<template>
  <div class="row contenedor" v-if="renderComponent">
    <div class="col s12 left-align">
      <div class="custom-h4 fc-red-petsa">
        Cotización
      </div>
    </div>
    <div class="v-alert" id="div_empty_cart" v-show="total_cart.articles < 1">
      <div class="col s12 m12">
        <i class="inline-icon material-icons">info</i>
        Su carrito está vacío. Visite nuestro&nbsp;
        <router-link to="/products">
          catálogo de productos
        </router-link>
      </div>
    </div>
    <div class="v-alert hide" id="div_eliminated_product">
      <div class="col s12 m8 l9">
        <i class="inline-icon material-icons">info</i>
        Se elimino el producto&nbsp;
        <span id="eliminated_product">{{eliminated_product.name}}</span>
      </div>
      <div class="col s12 m2 l1 display-flex">
        <span class="undo" @click="undoDelete()">
          ¿Deshacer?
        </span>
      </div>
      <div class="col s12 m2 l1">
        <i class="material-icons inline-icon btn-icon" title="Cerrar" @click="closeAlert('div_eliminated_product')">
          close
        </i>
      </div>
    </div>
    <div class="v-alert hide" id="div_empy_cart">
      <div class="col s12 m8 l9">
        <i class="inline-icon material-icons">info</i>
        Todos los productos eliminados
      </div>
      <div class="col s12 m2 l1 display-flex">
        <span class="undo" @click="undoEmptyCart()">
          ¿Deshacer?
        </span>
      </div>
      <div class="col s12 m2 l1">
        <i class="material-icons inline-icon btn-icon" title="Cerrar" @click="closeAlert('div_empy_cart')">
          close
        </i>
      </div>
    </div>

    <div class="col s12 m6 offset-m3 push-l9 l3 cart-total bottom" v-show="total_cart.articles >= 1">
      <div class="col s12 bottom bottom-titles">
        <b>Total del carrito</b>
      </div>
      <div class="col s12 bottom-items">
        <div class="col s4 left-align bottom-total">
          <b>Artículos</b>
        </div>
        <div class="col s8 right-align bottom-total">
          {{total_cart.items}}
        </div>
      </div>
      <div class="col s12">
        <div class="col s4 left-align bottom-total">
          <b>Total</b>
        </div>
        <div class="col s8 right-align bottom-total">
          $ {{formatPesos(total_cart.total)}}
        </div>
      </div>
      <div class="col s12">
        <a class="btn-checkout waves-effect" @click="openModal()">
          Solicitar cotización
        </a>
      </div>
    </div>

    <div class="show-on-medium-and-down hide-on-large-only" v-show="total_cart.articles >= 1">
      <div class="col s12" v-show="total_cart.articles >= 1">
        <div class="col s12 bottom bottom-titles">
          <div class="col s4">
            <b>Producto</b>
          </div>
          <div class="col s8">
            <b>Detalles</b>
          </div>
        </div>
        <div id="div_products_small">
          <div class="col s12 bottom bottom-items over-flow-hidden"
            v-for="a in articles" :key="a.id" :id="'div-s-'+a.id">
            <div class="col s4 over-flow-hidden align-items-center fs-12">
              <router-link :to="'/product/'+a.id + '&' + id_branch">
                <img v-if="a.img == null" src="@/assets/img/no-image-icon.png" 
                  alt="producto" class="img-product">
                <img v-else :src="a.img" :alt="a.code" class="img-product">
              </router-link>
            </div>
            <div class="col s8 fs-12">
              <router-link :to="'/product/'+a.id + '&' + id_branch">
                <p class="margin-0 black-text">
                  {{a.name}}
                </p>
              </router-link>
              <b>Importe: </b>
              $ {{formatPesos(a.total)}}
            </div>
            <div class="col s12">
              <div class="col s4">
                <div class="fs-12">
                  $ {{formatPesos(a.price)}}
                </div>
              </div>
              <div class="col s6">
                <div class="number" style="margin-bottom: 5px;">
                  <button class="minus waves-effect waves-light" @click="editArticle(a, -1)">
                    <i class="small material-icons">remove</i>
                  </button>
                  <input class="browser-default quantity" type="number"
                  :id="'quantity-input-s-' + a.id" :value="a.quantity" min="1" step="1"
                  pattern="\d+" @change="getValueSmall(a)" title="Cantidad" name="Cantidad"/>
                  <label class="hide" :for="'quantity-input-s-' + a.id">
                    Cantidad
                  </label>
                  <button class="plus waves-effect waves-light" @click="editArticle(a, 1)">
                    <i class="small material-icons">add</i>
                  </button>
                </div>
              </div>
              <div class="col s2">
                <img class="btn-icon btn-delete"
                  src="@/assets/img/icon-delete.png" @click="deleteArticle(a)"
                  alt="borrar" title="Borrar">
              </div>
            </div>
            <transition name="slide-fade">
              <div class="col s12 m12 text-center" v-if="a.quantity > a.maxQuantity">
                <div class="alert_max">
                  <i class="tiny inline-icon material-icons">priority_high</i>
                  Es posible que no tengamos inventario suficiente para surtir esta cantidad.
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="col s12" title="Vaciar Carrito" v-show="total_cart.articles >= 1">
        <a @click="emptyCart('click')" class="btn-floating btn-small waves-effect right
        waves-light bg-petsa display-flex justify-content-center align-items-center">
          <img src="@/assets/img/icon-delete-white.png"
            alt="borrar">
        </a>
      </div>
    </div>

    <div class="col m12 pull-l3 l9 hide-on-med-and-down" v-show="total_cart.articles >= 1">
      <div class="col s12 bottom bottom-titles">
        <div class="col s5">
          <b>Producto</b>
        </div>
        <div class="col s2">
          <b>Precio</b>
        </div>
        <div class="col s2">
          <b>Cantidad</b>
        </div>
        <div class="col s2">
          <b>Importe</b>
        </div>
        <div class="col s1">
          <b>&nbsp;</b>
        </div>
      </div>
      <div id="div_products">
        <div class="col s12 bottom-items"
          v-for="a in articles" :key="a.id" :id="'div-'+a.id">
          <div class="display-flex bottom over-flow-hidden">
            <div class="col s2 display-flex over-flow-hidden align-items-center"
              id="div-img-product">
              <router-link :to="'/product/'+a.id + '&' + id_branch">
                <img v-if="a.img == null" src="@/assets/img/no-image-icon.png" alt="producto">
                <img v-else :src="a.img" :alt="a.code">
              </router-link>
            </div>
            <div class="col s3 display-flex align-items-center left-align">
              <router-link :to="'/product/'+a.id + '&' + id_branch">
                <p class="black-text link_product">
                  {{a.name}}
                </p>
              </router-link>
            </div>
            <div class="col s2 display-flex align-items-center justify-content-right">
              $ {{formatPesos(a.price)}}
            </div>

            <div class="col s2 display-flex align-items-center justify-content-center">
              <div class="number">

                <button class="minus waves-effect waves-light minus-show-left"
                  @click="editArticle(a, -1)">
                  <i class="small material-icons">remove</i>
                </button>

                <button class="plus waves-effect waves-light plus-show-top"
                  @click="editArticle(a, 1)">
                  <i class="small material-icons">add</i>
                </button>

                <input class="browser-default quantity" type="number"
                :id="'quantity-input-' + a.id" :value="a.quantity" min="1" step="1"
                pattern="\d+" @change="getValue(a)" title="Cantidad" name="Cantidad"/>
                <label class="hide" :for="'quantity-input-' + a.id">
                      Cantidad
                  </label>
                <button class="plus waves-effect waves-light plus-show-right"
                  @click="editArticle(a, 1)">
                  <i class="small material-icons">add</i>
                </button>
                <button class="minus waves-effect waves-light minus-show-down"
                  @click="editArticle(a, -1)">
                  <i class="small material-icons">remove</i>
                </button>
              </div>
            </div>
            <div class="col s2 display-flex align-items-center justify-content-right">
              $ {{formatPesos(a.total)}}
            </div>
            <div class="col s1 display-flex align-items-center justify-content-center">
              <img class="btn-icon btn-delete"
                src="@/assets/img/icon-delete.png" @click="deleteArticle(a)"
                alt="borrar" title="Borrar">
            </div>
          </div>
          <transition name="slide-fade">
            <div class="col s12 m12 text-center" v-if="a.quantity > a.maxQuantity">
              <div class="alert_max">
                <i class="tiny inline-icon material-icons">priority_high</i>
                Es posible que no tengamos inventario suficiente para surtir esta cantidad.
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="col s1 offset-s11" data-title="Vaciar Carrito">
        <a @click="emptyCart('click')" class="btn-floating btn-small waves-effect
        waves-light right display-flex justify-content-center align-items-center bg-petsa"
        style="margin-top: 5px;">
          <img src="@/assets/img/icon-delete-white.png" alt="borrar">
        </a>
      </div>
    </div>

    <div id="modalSolicitud" class="modal modal-fixed-footer">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form  @submit.prevent="handleSubmit(request)" ref="form">
        <div class="modal-content">
          <div class="row">
            <div v-if="out_stock_articles.length > 0" class="col s12">
              <div class="col s12 bottom bottom-titles">
                <div class="col s12 m7">
                  <b>Producto</b>
                </div>
                <div class="col m4  hide-on-small-only">
                  <b>Cantidad</b>
                </div>
                <div class="col m1 hide-on-small-only">
                  <b>&nbsp;</b>
                </div>
              </div>
              <div id="div_products_out_stock">
                <div class="col s12 bottom-items"
                  v-for="a in out_stock_articles" :key="a.id" :id="'div-os-'+a.id">
                  <div class="display-flex bottom over-flow-hidden">
                    <div class="col s6 m6 l3 display-flex over-flow-hidden align-items-center justify-content-center"
                      id="div-img-product-os">
                      <router-link :to="'/product/'+a.id">
                        <img v-if="a.img == null" src="@/assets/img/no-image-icon.png"
                          alt="producto">
                        <img v-else :src="a.img" alt="producto">
                      </router-link>
                    </div>
                    <div class="col s6 m6 l4 display-flex align-items-center left-align">
                      <router-link :to="'/product/'+a.id">
                        <p class="black-text link_product">
                          {{a.name}}
                        </p>
                      </router-link>
                    </div>

                    <div class="col s10 m6 l4 display-flex align-items-center justify-content-center">
                      <div class="number">

                        <button class="minus waves-effect waves-light minus-show-left"
                          @click="editArticle(a, -1)">
                          <i class="small material-icons">remove</i>
                        </button>

                        <button class="plus waves-effect waves-light plus-show-top">
                          <i class="small material-icons">add</i>
                        </button>

                        <input class="browser-default quantity" type="number"
                        :id="'quantity-input-os-' + a.id" :value="a.quantity" min="1" step="1"
                        pattern="\d+" title="Cantidad" name="Cantidad" disabled/>
                        <label class="hide" :for="'quantity-input-os-' + a.id">
                              Cantidad
                          </label>
                        <button class="plus waves-effect waves-light plus-show-right">
                          <i class="small material-icons">add</i>
                        </button>
                        <button class="minus waves-effect waves-light minus-show-down"
                          @click="editArticle(a, -1)">
                          <i class="small material-icons">remove</i>
                        </button>
                      </div>
                    </div>
                    <div class="col s2 m6 l1 display-flex align-items-center justify-content-center">
                      <img class="btn-icon btn-delete"
                        src="@/assets/img/icon-delete.png" @click="deleteArticle(a)"
                        alt="borrar" title="Borrar">
                    </div>
                  </div>
                </div>
              </div>

              <div class="alert_max">
                <i class="tiny inline-icon material-icons">priority_high</i>
                Es posible que no tengamos inventario suficiente para surtir
                estos productos.
              </div>
            </div>
            <div class="col s12 left-align">
              <div class="custom-h5 fc-red-petsa">
                Enviar solicitud de cotización
              </div>
              <span>
                Proporcione sus datos y en breve uno de nuestros agentes se
                comunicará con usted para concretar los detalles de su pedido.
              </span>
            </div>
            <div class="separador">

              <div class="input-field col s12 m8">
                <label class="active" for="customer_name">Nombre</label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <input id="customer_name" name="Nombre" ref="customer_name" type="text"
                  v-model="customer.customer_name" class="validate"/>
                  <span class="helper-text red-text">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </div>
              <div class="input-field col s12 m4">
                <label class="active" for="customer_rfc">RFC</label>
                <validation-provider rules="" v-slot="{ errors }">
                  <input id="customer_rfc" ref="customer_rfc" type="text"
                  v-model="customer.customer_rfc" class="validate">
                  <span class="helper-text red-text">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </div>
              <div class="input-field col s12 m8">
                <label class="active" for="customer_email">Correo electrónico</label>
                <validation-provider rules="required|email" v-slot="{ errors }">
                  <input id="customer_email" ref="customer_email" type="text" name="Correo"
                  v-model="customer.customer_email" class="validate">
                  <span class="helper-text red-text">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </div>
              <div class="input-field col s12 m4">
                <label class="active" for="customer_phone">Teléfono</label>
                <validation-provider rules="" v-slot="{ errors }">
                  <input id="customer_phone" ref="customer_phone" type="text" name="Teléfono"
                  v-model="customer.customer_phone" class="validate">
                  <span class="helper-text red-text">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </div>
              <div class="input-field col s12">
                <label class="active" for="customer_city">Ciudad</label>
                <validation-provider rules="required" v-slot="{ errors }">
                <input id="customer_city" ref="customer_city" type="text" name="Ciudad"
                  v-model="customer.customer_city" class="validate">
                  <span class="helper-text red-text">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </div>

            </div>
            <div class="col s12" style="text-align: start;">
              <ul>
                <li>
                  Todos los precios incluyen I.V.A.
                </li>
                <li>
                  Los precios pueden variar mientras la compra no se concrete.
                </li>
                <li>
                  Los productos pueden ser ligeramente distintos a los mostrados en las fotografías.
                </li>
                <li>
                  He leido el
                  <a class="modal-trigger" href="#modalADP">
                    aviso de privacidad.
                  </a>
                </li>
              </ul>
              <p>
                <label>
                  <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                    <input type="checkbox" ref="customer_agreed" v-model="agreed"
                    :checked="agreed" id="customer_agreed" name="De acuerdo" />
                    <span class="black-text">De acuerdo</span>
                    <span class="helper-text red-text">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </label>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a @click="clearModalSolicitud()" class="modal-close waves-effect waves btn-flat">
            Cancelar
          </a>
          <a @click="clearModalSolicitud()" class="waves-effect waves btn-flat">
            Limpiar
          </a>
           <button class="waves-effect waves btn-flat">Enviar</button>
        </div>
      </form>
      </ValidationObserver>
    </div>
    <div v-if="requesting" class="splash-screen">
      <Loader/>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
import _ from 'lodash';
import Loader from '../components/Loader/loader.vue'
import api from '@/api';
import apipost from '@/apipost';
import {ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'ShoppingCart',
  metaInfo: {
    title: 'Cotización',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'descriptionShoppingCart',
        name: 'description',
        content: `Recibe cotizaciones de los productos de tu elección.
        `
      },
    ]
  },
  data(){
    return{
      articles:[],
      eliminated_product:{},
      undo_empty_cart:[],
      total_cart:{},
      out_stock_articles:[],
      requesting: false,
      id_branch: null,
      customer:{
        customer_name:null,
        customer_rfc: null,
        customer_phone: null,
        customer_email: null,
        customer_city: null,
        items:[]
      },
      agreed: false,
      renderComponent: true
    }
  },
  components: {
    Loader,
    ValidationObserver,
    ValidationProvider
  },
  mounted() {
    this.initComponents();
    this.selectedBranch();
    this.getArticles();
    this.totalCart();
    this.pageViewed();
    //obtener id usuario google
    // try{
    //   let gaUserId = document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".");
    //   console.log(gaUserId);
    // }catch(e){
    //   console.log(e);
    // }

  },
  methods:{
    initComponents(){
      window.scrollTo(0, 0);
      let elems = document.getElementById('modalSolicitud');
      M.Modal.init(elems, {dismissible: !this.requesting});
    },
    getArticles(){
      if (localStorage.getItem("cartHash")){
        try{
          this.articles = JSON.parse(
            this.fromB64(localStorage.getItem("cartHash"))
          );
        }
        catch(e){
          //ignorated
          this.articles = [];
          console.log('no products');
          e;
        }
      }
    },
    selectedBranch(){
      if (localStorage.getItem("selectedBranch")){
        let sb = JSON.parse(localStorage.getItem("selectedBranch"));
        this.id_branch = sb.id_branch;
      }else{
        let db = JSON.parse(localStorage.getItem("defaultBranch"));
        this.id_branch = db;
      }
    },
    toB64(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function(match, p1) {
          return String.fromCharCode('0x' + p1);
        }
      ));
    },
    fromB64(str) {
      return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      }).join(''));
    },
    getValueSmall(art){
      let newQuantity=
      parseInt(document.getElementById('quantity-input-s-' + art.id).value);
      this.changeInput(art, newQuantity);
    },
    getValue(art){
      let newQuantity=
      parseInt(document.getElementById('quantity-input-' + art.id).value);
      this.changeInput(art, newQuantity);
    },
    changeInput(art, newQuantity){
      this.get_product(art);

      if(newQuantity > 0){
        art.quantity = newQuantity;
        art.total = art.price * art.quantity;
        this.$root.$emit('editArticleNQEvent', art.id, newQuantity);
        this.totalCart();
      }else{
        this.deleteArticle(art);
      }
    },
    editArticle(art, one){
      this.get_product(art);
      let outStockArt = this.out_stock_articles.find(a => a.id === art.id);

      let count = parseInt(art.quantity);
      (one > 0)? count++ : count--  ;

      if(count > 0){
        if(outStockArt != null){
          outStockArt.quantity = count;
          outStockArt.total = art.price * art.quantity;
          if(outStockArt.quantity <= art.maxQuantity){
            this.out_stock_articles = this.out_stock_articles.filter(a => a.id !== art.id);
          }
        }

        art.quantity = count;
        art.total = art.price * art.quantity;

        this.$root.$emit('editArticleEvent', art );
        this.totalCart();
      }
      else{
        this.deleteArticle(art);
      }
    },
    async get_product (art) {
      let objIndex = this.articles.find(a => a.id === art.id)

      try {
        let resp = await api.get('product_rows/' + art.id,{});
        let product = resp.data;
        objIndex.maxQuantity = product.stock;
        objIndex.price = parseFloat(product.ecommercePrice) + parseFloat(product.ecommerceTax);
        objIndex.total = objIndex.price * objIndex.quantity;
        this.requesting = false;
      }
      catch (error) {
        this.requesting = false;
        objIndex.maxQuantity = 1;
        console.log(error);
      }
      await this.$nextTick();
    },
    deleteArticle(article){
      this.eliminated_product = article;
      var exists = this.articles.find(a => a.id === article.id);
      if(exists != null){
        this.articles = this.articles.filter(a => a.id !== article.id);
        document.getElementById('div_eliminated_product').classList.remove('hide');
        this.$root.$emit('deleteArticleEvent', article);
        this.totalCart();
      }
      let outStockArt = this.out_stock_articles.find(a => a.id === article.id);
      if(outStockArt != null){
        this.out_stock_articles = this.out_stock_articles.filter(a => a.id !== article.id);
      }
      if(this.articles.length == 0){
        this.closeModal();
      }
    },
    undoDelete(){
      this.$root.$emit('addArticleEvent', this.eliminated_product);
      this.articles.push(this.eliminated_product);
      document.getElementById('div_eliminated_product').classList.add('hide');
      this.totalCart();
    },
    emptyCart(e){
      if(this.articles.length >= 1){
        this.undo_empty_cart = this.articles;
        for (let articleInCart of this.articles) {
          this.articles = this.articles.filter(a => a.id !== articleInCart.id);
          this.$root.$emit('deleteArticleEvent', articleInCart);
          this.totalCart();
        }
        this.articles = [];

        document.getElementById('div_eliminated_product').classList.add('hide');
        if(e == 'click'){
          document.getElementById('div_empy_cart').classList.remove('hide');
        }
      }
    },
    undoEmptyCart(){
      this.articles = this.undo_empty_cart;
      this.$root.$emit('undoEmptyCartEvent', this.articles);
      this.totalCart();
      document.getElementById('div_empy_cart').classList.add('hide');
    },
    async openModal(){
      window.scrollTo(0, 0);
      this.requesting = true;
      let elem = document.getElementById('modalSolicitud');
      let instance = M.Modal.getInstance(elem);
      instance.open();
      this.out_stock_articles = [];
      try{
        for (let articleInCart of this.articles) {
          this.changeInput(articleInCart, articleInCart.quantity);
          let objIndex = this.articles.find(a => a.id === articleInCart.id);
          let resp  = await api.get('product_rows/' + articleInCart.id,{});
          let articleInBranch = resp.data;
          objIndex.maxQuantity = articleInBranch.stock;

          if(objIndex.quantity > articleInBranch.stock){
            this.out_stock_articles.push(articleInCart);
          }
        }
      }catch (error) {
        console.log(error);
      }
      this.requesting = false;
    },
    closeModal(){
      window.scrollTo(0, 0);
      let elem = document.getElementById('modalSolicitud');
      let instance = M.Modal.getInstance(elem);
      instance.close();
    },
    closeAlert(idAlert){
      document.getElementById(idAlert).classList.add('hide');
      this.eliminated_product = {};
      this.undo_empty_cart = [];
    },
    formatPesos(num){
      try{
        return num.toLocaleString('es-MX',
          {minimumFractionDigits: 2, maximumFractionDigits: 2});
        }
      catch(e){
        //ignored
        e;
      }
    },
    totalCart(){
      if (localStorage.getItem("totalCartHash")){
        let hash = localStorage.getItem("totalCartHash");
        this.total_cart = JSON.parse(this.fromB64(hash));
      }else{
        this.total_cart = {articles: 0};
      }
    },
    clearModalSolicitud(){
      this.customer={
        customer_name:null,
        customer_rfc: null,
        customer_phone: null,
        customer_email: null,
        customer_city: null,
        items:[]
      };
      this.agreed = false;
      document.getElementById('customer_name').value = null;
      document.getElementById('customer_rfc').value = null;
      document.getElementById('customer_email').value = null;
      document.getElementById('customer_phone').value = null;
      document.getElementById('customer_city').value = null;
      document.getElementById('customer_agreed').checked = false;
    },
    pageViewed() {
      window.scrollTo(0, 0);
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
      this.$gtag.pageview("/cart");
    },
    async request () {
      this.requesting = true;
      if(this.agreed){
        this.customer.items = _.map(this.articles, (article) =>
          ({ 'product_id':article.id, 'quantity':article.quantity }));
        try {
          await apipost.post(`branches/${this.id_branch}/request`, this.customer);

          this.closeModal();
          M.toast({html: 'Recibimos su solicitud, en breve uno de nuestros agentes se comunicará con usted.',
            classes: 'rounded notification-toast success'});

          this.$gtag.event('solicitar-cotizacion-' + this.id_branch, {
            'event_category': 'solicitar-cotizacion-' + this.id_branch + '-' +
              this.customer.customer_email + '-' + this.formatPesos(this.total_cart.total),
            'event_label': 'solicitar-cotizacion',
            'value': 1
          });

          this.emptyCart();
          this.requesting = false;
        }catch (error) {
          this.closeModal();

          if (error.response.status == 500) {
            M.toast({html: 'Ocurrio un error en el servidor, intente mas tarde.',
            classes: 'rounded notification-toast warning'});
          } else {
            M.toast({html: 'No se pudo procesar su solicitud.',
            classes: 'rounded notification-toast warning'});
          }
          this.requesting = false;
          console.error(error);
          return;
        }
      }
      await this.$nextTick();
      this.requesting = false;
    },
  },
  watch: {
    articles: {
      handler() {
        localStorage.setItem('cartHash', this.toB64(JSON.stringify(this.articles)));
      },
      deep: true
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#div_products_small .img-product,
#div-img-product img,
#div-img-product-os img{
  width: 90%;
}
#div_products_small .img-product{
  max-width: 100px !important;
}
.minus, .plus{
  width:32px !important;
  height:32px !important;
  background:none !important;
  padding:0 !important;
  border:none !important;
  border-radius: 8px !important;
  vertical-align: middle !important;
  text-align: center !important;
  color: black !important;
}
.minus:hover, .plus:hover{
  background:#E2E4E5 !important;
}
.minus-show-left,.plus-show-right{
  display: inline-block;
}
.plus-show-top,.minus-show-down{
  display: none;
}
.bg-petsa{
  background: #DB272F !important;
}

.quantity{
  height:32px !important;
  width: 55px !important;
  text-align: center !important;
  font-size: 18px !important;
  border:none !important;
  border-left: 0;
  border-right: 0;
  display: inline-block !important;
  vertical-align: middle !important;
}
.btn-small img{
  width: 1.8rem;
  padding: 2px;
}
.btn-icon{
  width: 2rem;
  text-decoration: none;
  border-radius: 50%;
  vertical-align: middle !important;
  color: #56595A;
  cursor: pointer;
}
.btn-delete{
  width: 2rem;
  padding: 2px;
}
.btn-delete:hover{
  background: #E2E4E5!important;
}
.display-flex{
  display: flex !important;
}
.undo{
  text-decoration: underline;
  cursor: pointer;
}
.over-flow-hidden{
  overflow: hidden;
}
.align-items-center{
  align-items: center !important;
}
.justify-content-right{
  justify-content: right !important;
}
.justify-content-center{
  justify-content: center !important;
}
.align-items-center > a > img{
  max-width: 100% !important;
}
.bottom{
  margin-bottom: .5rem;
}
.bottom-titles{
  border-bottom: black 3px solid;
}
.bottom-items{
  border-bottom: gray 2px solid;
}
.margin-0{
  margin: 0 !important;
}
.fs-12{
  font-size: 12px !important;
}
.bottom-total{
  margin-bottom: 1.5rem;
}
.btn-checkout{
  padding: 10px 30px 5px 30px !important;
  text-transform: uppercase;
}
.link_product:hover{
  text-decoration: underline;
}
.alert_max{
  margin: 0 0 3px 0;
}
/* quitar flechas en input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 600px) {
  #div-img-product-os img{
    width: 100px;
  }
  .quantity {
    height: 25px !important;
    width: 40px !important;
    font-size: 14px !important;
  }
  .minus, .plus{
    width:25px !important;
    height:25px !important;
  }
  .v-alert{
    flex-direction: column !important;
  }
  .display-flex{
    display: block;
  }
  i.small {
    font-size: 1.5rem;
  }
  .alert_max{
    padding: 5px !important;
    margin: 5px 0 5px 0 !important;
    font-size: 12px !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 992px) {
  .modal .minus-show-left,.plus-show-right{
    display: none;
  }

  .modal .plus-show-top,.minus-show-down{
    display: inline-block;
  }
  .modal .quantity{
    width: 85% !important;
  }
}

@media only screen and (min-width: 1150px) and (max-width: 1300px) {
  .modal .quantity{
    width: 85% !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1489px) {
  .plus{
    border-radius:4px 4px 0 0 !important;
  }
  .minus{
    border-radius: 0 0 4px 4px !important;
  }
  .quantity{
    width: 80% !important;
  }
  .minus-show-left,.plus-show-right{
    display: none;
  }

  .plus-show-top,.minus-show-down{
    display: inline-block;
  }
}
</style>
