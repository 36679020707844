<template>
  <div>
    <nav class="nav-extended">
      <div class="hide top-nav" id="div-top-navbar"></div>
      <div class="gris-2-petsa">
        <div class="nav-wrapper contenedor-nav row" id="nav-sticky">
          <div id="nav-search">

            <div class="col s5 m4 l2 max-height-nav flex-center" id="div-logo">
              <router-link to="/" class="logo">
                <img class="responsive-img max-px" id="logo" 
                  src="@/assets/Logos/grupo1.png" alt="Grupo PETSA"/>
              </router-link>
            </div>

            <div class="col s7 m8 l2 max-height-nav flex-center" id="div-branch">
              <a href="#branch_select" class="dropdown-trigger"
                  data-target="branch_select">
                <div class="branch flex-center">
                  <div style="height: 25px !important;">
                    <img src="@/assets/img/icon-location.png"
                    alt="location" style="height:20px">
                    <span class="branch_change fc-red-petsa">Sucursal:</span>
                    <img src="@/assets/img/icon-arrow.png"
                    alt="arrow" style="height:18px">
                  </div>
                  <span class="branch_name black-text break-word"
                    v-if="this.selected_branch != null">
                    {{this.selected_branch.name_branch}}
                  </span>
                  <span class="branch_name black-text break-word"
                    v-else>
                    PETSA Express Tijuana Vía Rápida
                  </span>
                </div>
              </a>
            </div>

            <div class="col s2 m1 hide-on-large-only max-height-nav-1 flex-center" id="div-menu">
              <a href="#!" data-target="side-menu" class="sidenav-trigger black-text">
                <i class="material-icons">menu</i>
              </a>
            </div>

            <div class="col s8 m7 l5 max-height-nav-1 flex-center" id="div-search">
              <KeywordSearch/>
            </div>

            <div class="col m4 l3 max-height-nav-1 hide-on-small-only flex-center" id="div-cart">
              <router-link to="/cart" id="cart-desktop" @click="link_active"
              class="waves-effect waves-light btn btn_circular_icon">
                <i :data-icon-label="number_articles" class="material-icons
                  cart-icon right cart-notification">
                  shopping_cart
                </i>
                $ {{account.toLocaleString('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2})}}
              </router-link>
            </div>
            <div class="col s2 max-height-nav-1 hide-on-med-and-up flex-center" id="div-cart-small">
              <router-link to="/cart" id="cart-mobile" @click="link_active"
              class="waves-effect waves-light">
                <i :data-icon-label="number_articles" class="material-icons
                  cart-icon cart-notification black-text">
                  shopping_cart
                </i>
              </router-link>

            </div>
          </div>
        </div>
      </div>
      <div class="bg-red-petsa">
        <div class="nav-content container hide-on-med-and-down">
          <ul class="tabs tabs-transparent">
            <li id="li-index" class="tab waves-effect">
              <router-link to="/" id="index_page">
                Inicio
              </router-link>
            </li>
            <li id="li-products" class="tab" @click="link_active">
              <router-link class="waves-effect" to="/products" id="products_page" >
                Productos
              </router-link>
              <div class="categories-list" id="div-categories-list">
                <div v-for="(c, index) in categories" :key="index+'category'"
                  class="div-category-item" @mouseover="showSubCategory(index)">
                  <a @click="applyCategory(c.id)">{{c.name}}</a>
                  <div class="subcategories-list" id="div-subcategories-list" v-if="showSubCategoryList">
                    <div v-for="(cc, i) in subCategory" :key="i+'subcategory'">
                      <a class="div-subcategory-item" @click="applyCategory(cc.id)">
                        {{cc.name}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="overshadow"></div>
            </li>
            <li v-if="(show_offers && date <= validity || show_offers_traeesa && date <= validity_traeesa)"
               class="tab waves-effect" @click="link_active">
              <router-link to="/magazine" id="magazine_page" v-if="show_offers && date <= validity">
                Ofertas
              </router-link>
              <router-link to="/magazineTRAEESA" id="magazine_page" v-else-if="(show_offers_traeesa && date <= validity_traeesa)">
                Ofertas
              </router-link>
            </li>
            <li class="tab waves-effect" @click="link_active">
              <router-link to="/branchLocations" id="branchLocations_page">
                Ubicaciones
              </router-link>
            </li>
            <li class="tab waves-effect" @click="link_active">
              <router-link to="/services" id="services_page">
                Servicios
              </router-link>
            </li>
            <li class="tab waves-effect">
              <a class="modal-trigger" href="#modal1" id="modal_bill_trigger">
                Factura
              </a>
            </li>
            <li class="tab right eslogan">
              Líder en el ramo eléctrico,<span> con mas de 30 años de experiencia.</span>
            </li>
          </ul>
        </div>
      </div>

      <ul id="branch_select" class="dropdown-content branch_dropdown">
        <li class="branch_type no-hover" style="margin-top: 1rem"
        v-if="branches_self_service.length > 0">
          <div>
            <span>Autoservicio</span>
          </div>
        </li>
        <li v-for="(bss, index) in branches_self_service" :key="index+'bss'">
          <div class="card">
            <a @click="select_branch(bss.id, bss.name, 'Autoservicio', bss.show_offers, bss.show_offers_traeesa)">
              <div class="card-content">
                <span class="card-title break-word"><b>{{bss.name}}</b></span>
                <p class="branch_address break-word">
                  {{bss.address.street}} No.{{bss.address.number}}
                  {{bss.address.suburb}} {{bss.address.city.name}},
                  {{bss.address.city.state.name}} C.P. {{bss.address.zipCode}}
                </p>
                <span>Tel. {{bss.phone.split(",")[0]}}</span>
              </div>
              <div class="card-action">
                <a v-if="id_selected_branch == bss.id" class="selected no-hover">
                  Seleccionada
                </a>
                <a v-else>
                  Seleccionar
                </a>
              </div>
            </a>
          </div>
        </li>

        <li class="branch_type no-hover" v-if="branches.length > 0">
          <div>
            <span>Mostrador</span>
          </div>
        </li>
        <li v-for="(b, index) in branches" :key="index+'m'">
          <div class="card">
            <a @click="select_branch(b.id, b.name, 'Mostrador', b.show_offers, b.show_offers_traeesa)">
              <div class="card-content">
                <span class="card-title break-word"><b>{{b.name}}</b></span>
                <p class="branch_address break-word">
                  {{b.address.street}} No.{{b.address.number}}
                  {{b.address.suburb}} {{b.address.city.name}},
                  {{b.address.city.state.name}} C.P. {{b.address.zipCode}}
                </p>
                <span>Tel. {{b.phone.split(",")[0]}}</span>
              </div>
              <div class="card-action">
                <a v-if="id_selected_branch == b.id" class="selected no-hover">
                  Seleccionada
                </a>
                <a v-else>
                  Seleccionar
                </a>
              </div>
            </a>
          </div>
        </li>
      </ul>

      <ul id="side-menu" class="sidenav" @click="close_sidebar">
        <li class="header-sidenav"></li>
        <li id="li-index_page_mobile">
          <router-link to="/" id="index_page_mobile" class="waves-effect">
            Inicio
          </router-link>
        </li>
        <li @click="link_active" id="li-products_page_mobile">
          <router-link to="/products" id="products_page_mobile"
            class="waves-effect">
            Productos
          </router-link>
        </li>
        <li v-if="(show_offers && date <= validity || show_offers_traeesa && date <= validity_traeesa)" 
          @click="link_active" id="li-magazine_page_mobile">
          <router-link to="/magazine" id="magazine_page_mobile" v-if="show_offers && date <= validity" class="waves-effect">
            Ofertas
          </router-link>
          <router-link to="/magazineTRAEESA" id="magazine_page_mobile" v-else-if="(show_offers_traeesa && date <= validity_traeesa)" class="waves-effect">
            Ofertas
          </router-link>
        </li>
        <li @click="link_active" id="li-branch_page_mobile">
          <router-link to="/branchLocations" id="branch_page_mobile"
            class="waves-effect">
            Ubicaciones
          </router-link>
        </li>
        <li @click="link_active" id="li-services_page_mobile">
          <router-link to="/services" id="services_page_mobile"
            class="waves-effect">
            Servicios
          </router-link>
        </li>
        <li id="li-modal-factura_mobile">
          <a class="waves-effect modal-trigger" href="#modal1">Factura</a>
        </li>
        <li id="li-modal-contact_mobile">
          <a href="#modalContacto" id="contact_page_mobile"
            class="waves-effec modal-trigger">
            Contáctanos
          </a>
        </li>
      </ul>

    </nav>
    <div v-if="isLoading" class="splash-screen">
      <Loader/>
    </div>
    <div id="modalCambioSucursal" class="modal">
      <div class="modal-content">
        <i class="material-icons orange-text">warning</i>
        <div class="custom-h4">
          Cambio de sucursal
        </div>
        <p>Precio y disponibilidad de productos puede variar al cambiar de sucursal.</p>
      </div>
      <div class="modal-footer">
        <button class="modal-close waves-effect btn-checkout cancel">Cancelar</button>
        <button @click="modify_shopping_cart()" class="modal-close waves-effect btn-checkout">Aceptar</button>
      </div>
    </div>
  </div>
</template>

<script>
import M from 'materialize-css'
import api from '@/api';
import _ from 'lodash';
import KeywordSearch from '../KeywordSearch/keywordSearch.vue'
import Loader from '../Loader/loader.vue'

export default {
  name: 'Navbar',
  data(){
    return{
      selected_branch:null,
      id_selected_branch:null,
      show_offers:true,
      show_offers_traeesa: false,
      new_branch:null,
      articlesCart:[],
      articlesCartNewBranch: [],
      isLoading: false,
      subCategory: null,
      showSubCategoryList: true,
      date:new Date(),
      validity: new Date('May 1, 2025'),
      validity_traeesa: new Date('May 1, 2025'),
      filters: null,
      perPage: 1,
      page: 1
    }
  },
  props: {
    number_articles: Number,
    account: Number,
    branches_self_service: Array,
    branches: Array,
    categories: Array,
  },
  components: {
    KeywordSearch,
    Loader
  },
  mounted() {
    this.init_components();
    this.selectedBranch();
    const thisInstanceSelectBranch = this
    this.$root.$on('selectBranchEvent', function(id, name, type, show_offers, show_offers_traeesa){
      thisInstanceSelectBranch.select_branch(id, name, type, show_offers, show_offers_traeesa)
    });
    window.document.onscroll = () => {
      this.nav_sticky();
    }

  },
  methods:{
    init_components(){
      var elemsSidenav = document.querySelectorAll('.sidenav');
      M.Sidenav.init(elemsSidenav, {});

      var elemsDropdown = document.querySelectorAll('.dropdown-trigger');
      M.Dropdown.init(elemsDropdown, {coverTrigger:false, constrainWidth:false});

      var elemsModal = document.querySelectorAll('.modal');
      M.Modal.init(elemsModal, {});

      var elemsSelect = document.querySelectorAll('select');
      M.FormSelect.init(elemsSelect, {});
    },
    selectedBranch(){
      if (localStorage.getItem("selectedBranch")){
        this.selected_branch = null;
        let sb = JSON.parse(localStorage.getItem("selectedBranch"));
        this.selected_branch = sb;
        this.id_selected_branch = sb.id_branch;
        this.show_offers = sb.show_offers;
        this.show_offers_traeesa = sb.show_offers_traeesa;
      }
    },
    close_sidebar(){
      var elems = document.getElementById('side-menu');
      var instance = M.Sidenav.getInstance(elems);
      instance.close();
    },
    link_active(){
      document.getElementById('index_page').classList.remove('router-link-active');
      document.getElementById('index_page_mobile').classList.remove('router-link-active');
    },
    select_branch(id, name, type, show_offers, show_offers_traeesa, geolocation) {
      if (localStorage.getItem("cartHash")){
        try{
          this.articlesCart = JSON.parse(
            this.fromB64(localStorage.getItem("cartHash"))
          );
        }
        catch(e){
          //ignorated
          this.articlesCart = [];
          console.log('no products');
          e;
        }
      }
      let sb ={
        id_branch: id,
        name_branch: name,
        branch_type: type,
        show_offers: show_offers,
        show_offers_traeesa: show_offers_traeesa,
      };

      if(geolocation == true){
        this.new_branch = sb;
        this.modify_shopping_cart();
      }else{
        if(this.selected_branch == null){
          let defaultBranch = localStorage.getItem("defaultBranch");
          if(this.articlesCart.length == 0 || defaultBranch == id){
            this.selected_branch = sb;
            location.reload();
          }else{
            this.new_branch = sb;
            this.showPopup();
          }
        }else{
          if(this.articlesCart.length == 0){
            if(this.selected_branch.id_branch != id){
              this.selected_branch = sb;
              location.reload();
            }
          }else{
            if(this.selected_branch.id_branch != id){
              this.new_branch = sb;
              this.showPopup();
            }
          }
        }
      }
    },
    showPopup() {
      var modalConfirmacion = document.getElementById('modalCambioSucursal');
      var instance = M.Modal.getInstance(modalConfirmacion);
      instance.open();
    },
    showSubCategory(index){
      this.subCategory = null;
      this.subCategory = this.categories[index].children;
      if(this.subCategory != null){
        if (this.subCategory.length > 0) this.showSubCategoryList = true;
        else this.showSubCategoryList = false;
      }
    },
    async modify_shopping_cart(){
      this.articlesCartNewBranch = [];
      this.isLoading = true;

      try{
        for (let articleInCart of this.articlesCart) {
          this.filters = _.assign({},
            {
              'branchProducts.branchId': this.new_branch.id_branch,
              'product_id': articleInCart.productId,
            },
          );
          let resp  = await api.get('product_rows/',{
            params: _.pick(this, 'filters', 'page', 'perPage'),
          });
          let product = resp.data.data[0];

          if(product != null){
            let ecommercePriceTaxed = parseFloat(product.ecommercePrice) + parseFloat(product.ecommerceTax);
            let total = articleInCart.quantity * ecommercePriceTaxed;

            this.articlesCartNewBranch.push(
              {
                id: product.id,
                productId: product.productId,
                quantity: articleInCart.quantity,
                price: ecommercePriceTaxed,
                img: articleInCart.img,
                name: product.description,
                total: total,
                maxQuantity: product.stock
              }
            );
          }
        }
        this.selected_branch = this.new_branch;
      }catch (error) {
        console.log(error);
      }
      localStorage.setItem('cartHash',
        this.toB64(JSON.stringify(this.articlesCartNewBranch)));
      this.totalNewCart();
      this.isLoading = false;
      location.reload();
    },
    totalNewCart(){
      let totalCart = {};
      if(this.articlesCartNewBranch.length == 0){
        totalCart = {
          total: 0,
          articles: 0,
          items: 0,
        }
      }
      else{
        let total_account = this.articlesCartNewBranch.map(a => a.total)
          .reduce((prev, next) => prev + next);
        let number_articles = this.articlesCartNewBranch.length;
        let total_items = this.articlesCartNewBranch.map(a => a.quantity)
          .reduce((prev, next) => prev + next);
        totalCart = {
          total: total_account,
          articles: number_articles,
          items: total_items,
        }
      }
      localStorage.setItem('totalCartHash',
          this.toB64(JSON.stringify(totalCart)));
    },
    toB64(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function(match, p1) {
          return String.fromCharCode('0x' + p1);
        }
      ));
    },
    fromB64(str) {
      return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      }).join(''));
    },
    nav_sticky(){
      let navBar = document.getElementById('nav-sticky');
      let navSearch = document.getElementById('nav-search');
      let divLogo = document.getElementById('div-logo');
      let divBranch = document.getElementById('div-branch');
      let divTopNavbar = document.getElementById('div-top-navbar');
      var width = document.body.clientWidth;

      if(window.scrollY > 200){
        navBar.classList.add('sticky-nav');
        navSearch.classList.add('contenedor-nav');
        navSearch.classList.add('row');
        divTopNavbar.classList.remove('hide');
        if(width < 993){
          divLogo.classList.add('hide');
          divBranch.classList.add('hide');
        }
      } else {
        navBar.classList.remove('sticky-nav');
        navSearch.classList.remove('contenedor-nav');
        navSearch.classList.remove('row');
        divLogo.classList.remove('hide');
        divBranch.classList.remove('hide');
        divTopNavbar.classList.add('hide');
      }
    },
    applyCategory(id){
      let name = 'products';
      let query = _.assign(
        {},
        this.$route.name === name ? _.omit(this.$route.query, 'page') : {},
        {categoryId: id},
      );
      sessionStorage.setItem('categoryId', id);
      try {
        this.$router.push({ name, query });
      } catch (error) {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }
    },
  },
  watch: {
    selected_branch: {
      handler() {
        localStorage.setItem('selectedBranch', JSON.stringify(this.selected_branch));
      },
      deep: true
    },
    categories:{
      handler() {
        this.showSubCategory(0);
      },
      deep: true
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contenedor-nav {
  width: 90%;
  max-width: 2304px;
}
.container{
  width: 85% !important;
  max-width: 2176px;
}
.row{
  margin-bottom: 0;
}
.max-height-nav{
  height: 90px;
}
.max-height-nav-1{
  height: 90px;
}
.top-nav{
  height: 90px;
}
.tabs{
max-height: 40px;
}
.tabs .tab{
max-height: 40px;
line-height: 40px !important;
}
.tabs {
  font-family: "Avenir-Bold";
  overflow-x: visible !important;
  overflow-y: initial !important;
}
.logo{
  margin-top: 12%;
}
.max-px{
  width: 90% !important;
  max-width: 200px !important;
  height: auto;
}
#overshadow {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 998;
}
#li-products{
  overflow: visible !important;
  position: relative !important;
  display: inline-block !important;
}
#li-products .categories-list{
  background-color: #fff !important;
}
#li-products .categories-list,
#li-products .subcategories-list{
  visibility: hidden;
  position: absolute;
  background-color: #eee;
  z-index: 999;
  opacity: 0;
  height: 0;
  width: 0;
}
#li-products .categories-list{
  top: 90%;
  left: 0;
  right: 0;
}
#li-products .subcategories-list{
  min-width: 150%;
  top: 0;
  left: 100% !important;
  right: 250% !important;
  height: 100% !important;
  overflow: auto; 
}

#li-products .div-category-item,
#li-products .div-subcategory-item{
  height: 36px;
  line-height: 36px;
}

#li-products:hover > .categories-list,
#li-products .div-category-item:hover > .subcategories-list
{
  visibility: visible;
  opacity: 1;
  height: auto;
  width: auto;
  transition: all 0.5s ease;
}

#li-products .categories-list a,
#li-products .subcategories-list a{
  padding: 0 0 0 5px !important;
  text-align: left !important;
  font-size: 12px !important;
  color: #000 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 0;
  right: 0;
}

#li-products .categories-list .div-category-item:hover{
  background: #DB272F !important;
}
#li-products .categories-list .div-category-item:hover > a{
  color: #fff !important;
  font-size: 12.5px !important;
}
#li-products .categories-list a:hover{
  color: #fff !important;
  font-size: 12.5px !important;
  background: #DB272F !important;
}
.eslogan{
  font-family: "Avenir-Regular";
  font-size: 15px !important;
  font-weight: 800;
  text-transform: none !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.eslogan span{
  font-family: "Avenir-Bold";
}
.tabs.tabs-transparent .tab a,
.tabs.tabs-transparent .tab.disabled a,
.tabs.tabs-transparent .tab.disabled a:hover {
  color: #FFF;
}
.tabs.tabs-transparent .tab a:hover{
  color:#000;
  background: #E2E4E5;
}
.router-link-active:not(.btn_circular_icon,.logo){
  color: #FFF !important;
  background: rgba(246,178,181,0.2);
}
.router-link-active:hover{
  color: #000 !important;
}
.bg-red-petsa{
  background: #DB272F;
}
.gris-2-petsa{
  background: #E2E4E5;
}
/* Dropdown mas */
#dropdown1 {
  min-width: 155px;
  max-width: 165px;
}
#dropdown1 .divider {
  height: 1px !important;
  max-height: 1px !important;
}
#dropdown1 li {
  min-height: 30px;
  max-height: 35px;
}
#dropdown1 li a.router-link-active, .sidenav li a.router-link-active{
  color: red !important;
}
/* Sucursal seleccionada*/
.branch{
  cursor: pointer;
  word-break: break-all !important;
  display: inline-block !important;
}
#div-branch{
  justify-content: center !important;
}
.branch .branch_change {
  font-family: "Avenir-Bold";
  font-size: 15px;
  color: #000;
  /* line-height: 2; */
}
.branch .branch_name {
  font-family: "Avenir-Bold";
  display: inline-block !important;
  color: #fff;
  font-size: 13px;
  font-weight: 800;
  line-height: 1;
}
.location-icon{
  line-height: 4rem !important;
  height: 0 !important;
}
#location{
  font-size: 20px !important;
}
#branch_change_arrow{
  font-size: 30px !important;
}
/* Selección sucursal */
.branch_dropdown{
  width: 18% !important;
  height: 400px !important;
}
.branch_info_dropdown{
  padding: .5rem;
  min-height: 90px;
}
.branch_type{
  font-family: "Avenir-Bold";
  min-height: 20px;
  max-height: 22px !important;
  text-align: center;
}
.branch_type span{
  color: #DB272F;
  font-size: 18px;
}
.branch_address{
  font-family: "Avenir-Regular";
  color: #000;
  font-size: 12px !important;
}
#branch_select .card .card-title {
  font-family: "Avenir-Bold";
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #000;
}
#branch_select .card .card-content {
  padding: 5px !important;
  height: auto;
  overflow: hidden;
}
#branch_select .card .card-content .card-title {
  margin-bottom: 0 !important;
  line-height: normal;
}
#branch_select .card .card-content p {
  line-height: normal;
}
#branch_select .card .card-content span {
  color: #000;
  font-size: 12px;
}
#branch_select .card .card-action .selected{
  background: white !important;
  border: #DB272F solid 2px !important;
  color: #DB272F !important;
}
#branch_select .card a:hover {
  background: none !important;
}
#branch_select .card a a:hover {
  background: #DB272F !important;
  color: white !important;
  box-shadow: 0 8px 17px 2px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
}

.no-hover{
  pointer-events: none;
}
.break-word{
  word-break: break-word;
}
.truncate{
  display: block !important;
  overflow: visible !important;
}
/* navbar sticky */
.sticky-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #E2E4E5;
  transition: all 0.35s ease;
  width: 100% !important;
  max-width: 100% !important;
  z-index: 999;
}
/* Buscador */
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
#div-logo{
  justify-content: flex-end !important;
}
/* Carrito */
.cart-icon{
  height: 30px !important;
  width: 30px !important;
  line-height: 3rem;
}
.btn_circular_icon{
  font-family: 'Avenir-Regular';
  background: #f2f2f2;
  color: #4d4d4d;
  font-size: 13px;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  transition: all 0.3s;
  -webkit-transition: all 0.2s;
  border: 0px;
  padding: 5px 10px !important;
}
.btn_circular_icon:focus{
  background: #56595A !important;
  color: #fff !important;
}
.cart-notification:after {
  content: attr(data-icon-label);
  position: absolute;
  font-style: normal;
  font-family: Arial,Sans-serif!important;
  top: 5px;
  right: 5px;
  font-weight: bolder;
  background: #EF2D31;
  -webkit-box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 30%);
  box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 30%);
  font-size: 11px;
  padding-left: 2px;
  padding-right: 2px;
  opacity: .9;
  line-height: 17px;
  letter-spacing: -.5px;
  height: 17px;
  min-width: 17px;
  border-radius: 99px;
  color: #fff;
  text-align: center;
  z-index: 1;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  -o-transition: transform .2s;
  transition: transform .2s;
  transition: transform .2s,-webkit-transform .2s;
}
#cart-desktop:hover, #cart-mobile:hover{
  background: #56595A !important;
  color: #fff !important;
}
/* Style Sidenav */
.sidenav{
  background: #E2E4E5 !important;
}
.sidenav li>a {
  font-family: "Avenir-Bold";
  color: rgba(0,0,0) !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  text-align: initial !important;
  border-bottom: 2px rgba(219, 39, 47, 0.3) solid;
}
.sidenav .subheader {
  font-family: "Avenir-Bold";
  color: rgba(0,0,0,0.54);
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 40px !important;
  text-align: center !important;
  border-bottom: 0 !important;
}
.header-sidenav{
  background: #DB272F;
  height: 5%;
}
.footer-sidenav{
  left: 0;
  bottom: 0;
  width: 100%;
  background: black;
}
.unsaved-changes-dialog > .desktop-dialog--wrapper {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
@media screen and (max-width: 600px) {
  .contenedor-nav {
    width: 100% !important;
  }
  .branch_dropdown{
    width: 60% !important;
  }
  .max-height-nav-1{
    height: 60px;
  }
  .top-nav{
    height: 150px;
  }
  .cart-notification:after {
    top: 0;
    right: 0;
    padding-left: 0;
  }
  .search-icon{
    top: 0 !important;
  }
  #branch_select .card .card-action a{
    font-size: 12px !important;
    padding: 10px 10px 5px 10px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 742x) {
  .btn_circular_icon{
    font-size: 8px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .branch_dropdown{
    width: 40% !important;
  }
  .card .card-content {
    height: 100px;
  }
  .max-height-nav-1{
    height: 60px;
  }
  .top-nav{
    height: 120px;
  }
  .search-icon{
    top: 0 !important;
  }
  #branch_select .card .card-action a{
    font-size: 12px !important;
    padding: 10px 10px 5px 10px !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1169px) {
  .branch_dropdown{
    width: 20vw !important;
  }
  .eslogan{
    display: none !important;
  }
  .branch .branch_name{
    font-size: 11px !important;
  }
  #branch_select .card .card-action a{
    font-size: 12px !important;
    padding: 10px 10px 5px 10px !important;
  }
}
@media only screen and (min-width: 1170px) and (max-width: 1299px) {
  .branch_dropdown{
    width: 22vw !important;
  }
  .branch .branch_name{
    font-size: 12px !important;
  }
  .eslogan{
    display: none !important;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1400px) {
   #branch_select .card .card-action a{
    font-size: 12px !important;
    padding: 10px 10px 5px 10px !important;
  }
}
</style>
