<template>
  <div id="home">
    <h1 class="seo-show">Productos Eléctricos Tijuana</h1>
    <h1 class="seo-show">Material Eléctrico Tijuana</h1>
    <h1 class="seo-show">Ferreteria Tijuana</h1>
    <h1 class="seo-show">Plomeria y Electricidad Tijuana</h1>
    <h1 class="seo-show">Material Construcción Tijuana</h1>
    <h1 class="seo-show">Transformadores Eléctricos Tijuana, Transformadores Tijuana</h1>
    <h2 class="seo-show">Herramientas Tijuana, Herramienta Tijuana</h2>

    <h1 class="seo-show">Productos Eléctricos Rosarito</h1>
    <h1 class="seo-show">Material Eléctrico Rosarito</h1>
    <h1 class="seo-show">Ferreteria Rosarito</h1>
    <h1 class="seo-show">Plomeria y Electricidad Rosarito</h1>
    <h1 class="seo-show">Material Construcción Rosarito</h1>
    <h1 class="seo-show">Transformadores Eléctricos Rosarito, Transformadores Rosarito</h1>
    <h2 class="seo-show">Herramientas Rosarito, Herramienta Rosarito</h2>

    <h1 class="seo-show">Productos Eléctricos Tecate</h1>
    <h1 class="seo-show">Material Eléctrico Tecate</h1>
    <h1 class="seo-show">Ferreteria Tecate</h1>
    <h1 class="seo-show">Plomeria y Electricidad Tecate</h1>
    <h1 class="seo-show">Material Construcción Tecate</h1>
    <h1 class="seo-show">Transformadores Eléctricos Tecate, Transformadores Tecate</h1>
    <h2 class="seo-show">Herramientas Tecate, Herramienta Tecate</h2>

    <h1 class="seo-show">Productos Eléctricos Ensenada</h1>
    <h1 class="seo-show">Material Eléctrico Ensenada</h1>
    <h1 class="seo-show">Ferreteria Ensenada</h1>
    <h1 class="seo-show">Plomeria y Electricidad Ensenada</h1>
    <h1 class="seo-show">Material Construcción Ensenada</h1>
    <h1 class="seo-show">Transformadores Eléctricos Ensenada, Transformadores Ensenada</h1>
    <h2 class="seo-show">Herramientas Ensenada, Herramienta Ensenada</h2>

    <h1 class="seo-show">Productos Eléctricos Mexicali</h1>
    <h1 class="seo-show">Material Eléctrico Mexicali</h1>
    <h1 class="seo-show">Ferreteria Mexicali</h1>
    <h1 class="seo-show">Plomeria y Electricidad Mexicali</h1>
    <h1 class="seo-show">Material Construcción Mexicali</h1>
    <h1 class="seo-show">Transformadores Eléctricos Mexicali, Transformadores Mexicali</h1>
    <h2 class="seo-show">Herramientas Mexicali, Herramienta Mexicali</h2>

    <h1 class="seo-show">Productos Eléctricos San Quintín</h1>
    <h1 class="seo-show">Material Eléctrico San Quintín</h1>
    <h1 class="seo-show">Ferreteria San Quintín</h1>
    <h1 class="seo-show">Plomeria y Electricidad San Quintín</h1>
    <h1 class="seo-show">Material Construcción San Quintín</h1>
    <h1 class="seo-show">Transformadores Eléctricos San Quintín, Transformadores San Quintín</h1>
    <h2 class="seo-show">Herramientas San Quintín, Herramienta San Quintín</h2>

    <h1 class="seo-show">Productos Eléctricos La Paz</h1>
    <h1 class="seo-show">Material Eléctrico La Paz</h1>
    <h1 class="seo-show">Ferreteria La Paz</h1>
    <h1 class="seo-show">Plomeria y Electricidad La Paz</h1>
    <h1 class="seo-show">Material Construcción La Paz</h1>
    <h1 class="seo-show">Transformadores Eléctricos La Paz, Transformadores La Paz</h1>
    <h2 class="seo-show">Herramientas La Paz, Herramienta La Paz</h2>

    <h1 class="seo-show">Productos Eléctricos San José del Cabo</h1>
    <h1 class="seo-show">Material Eléctrico San José del Cabo</h1>
    <h1 class="seo-show">Ferreteria San José del Cabo</h1>
    <h1 class="seo-show">Plomeria y Electricidad San José del Cabo</h1>
    <h1 class="seo-show">Material Construcción San José del Cabo</h1>
    <h1 class="seo-show">Transformadores Eléctricos San José del Cabo, Transformadores San José del Cabo</h1>
    <h2 class="seo-show">Herramientas San José del Cabo, Herramienta San José del Cabo</h2>

    <SlickCarousel/>
    <br>
    <Sections :categories="categories"/>
    <Minibanner v-if="show_offers && date <= validity"/>
    <MinibannerTRAEESA v-else-if="(show_offers_traeesa && date <= validity_traeesa)"/>
    <div class="row contenedor">
      <div class="col s12 text">
        <div class="custom-h4 fc-gray-petsa" v-if="this.selected_branch != null">
          Ahora en {{this.selected_branch.name_branch}}
        </div>
        <div class="custom-h4 fc-gray-petsa" v-else>
          Ahora en PETSA Express Tijuana
        </div>
        <hr>
      </div>
      <div class="col s12">
        <ProductHome :categories="categories"/>
      </div>
    </div>
  </div>
</template>

<script>
import SlickCarousel from '../components/SlickCarousel/slickCarousel.vue'
import Sections from '../components/Sections/sections.vue'
import Minibanner from '../components/Minibanner/minibanner.vue'
import MinibannerTRAEESA from '../components/Minibanner/minibannerTRAEESA.vue'
import ProductHome from '../components/ProductsHome/ProductsInHome.vue'

export default {
  name: 'Home',
  metaInfo: {
    title: 'Inicio',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'descriptionHome',
        name: 'description',
        content: `Grupo PETSA ofrece un excelente servicio
        y una magnifica calidad en productos, cubriendo así la demanda de
        material eléctrico en el mercado de ambas Californias.`
      },
      {
        name: 'keywords',
        content: `Material Eléctrico de Tijuana, Ferreteria Tijuana, Plomeria y Electricidad Tijuana,
        Transformadores Tijuana, Herramientas Tijuana, Cables Tijuana
        Material Eléctrico de Rosarito, Ferreteria Rosarito, Plomeria y Electricidad Rosarito,
        Transformadores Rosarito, Herramientas Rosarito, Cables Rosarito,
        Material Eléctrico de Tecate, Ferreteria Tecate, Plomeria y Electricidad Tecate,
        Transformadores Tecate, Herramientas Tecate, Cables Tecate,
        Material Eléctrico de Ensenada, Ferreteria Ensenada, Plomeria y Electricidad Ensenada,
        Transformadores Ensenada, Herramientas Ensenada, Cables Ensenada,
        Material Eléctrico de Mexicali, Ferreteria Mexicali, Plomeria y Electricidad Mexicali,
        Transformadores Mexicali, Herramientas Mexicali, Cables Mexicali,
        Material Eléctrico de La Paz, Ferreteria La Paz, Plomeria y Electricidad La Paz,
        Transformadores La Paz, Herramientas La Paz, Cables La Paz,
        Material Eléctrico de San José del Cabo, Ferreteria San José del Cabo, Plomeria y Electricidad San José del Cabo,
        Transformadores San José del Cabo, Herramientas San José del Cabo, Cables San José del Cabo`
      },
    ],
    link: [
      {rel: 'canonical', href: 'https://www.grupopetsa.com/'}
    ]
  },
  data(){
    return{
      selected_branch:null,
      id_branch:null,
      show_offers:true,
      show_offers_traeesa: false,
      date:new Date(),
      validity: new Date('May 1, 2025'),
      validity_traeesa: new Date('May 1, 2025'),
    }
  },
  props: {
    categories: Array,
  },
  components: {
    SlickCarousel,
    Sections,
    Minibanner,
    MinibannerTRAEESA,
    ProductHome,
  },
  mounted(){
    this.selectedBranch();
    this.pageViewed();
  },
  methods:{
    selectedBranch(){
      if (localStorage.getItem("selectedBranch")){
        this.selected_branch = null;
        let sb = JSON.parse(localStorage.getItem("selectedBranch"));
        this.selected_branch = sb;
        this.id_branch = sb.id_branch;
        this.show_offers = sb.show_offers;
        this.show_offers_traeesa = sb.show_offers_traeesa;
      }
      else{
        this.id_branch = localStorage.getItem("defaultBranch");
      }
    },
    pageViewed() {
      this.$gtag.pageview("/home");
    },
  }
}
</script>

<style>
#home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#div-categories .slick-prev,
#div-categories .slick-next {
  top: 35% !important;
}
#div-categories button.slick-prev:before, 
#div-categories button.slick-next:before{
 background: none !important;
}
#div-categories .slick-prev:before {
  content: '❮' !important;
}
#div-categories .slick-next:before {
  content: '❯' !important;
}
#div-categories .slick-next {
  margin-right: 0;
}
#div-categories .slick-prev {
  margin-left: -20px;
}
.slick-prev, .slick-next {
  top: 45% !important;
}
button.slick-prev:before, button.slick-next:before {
  color: #DB272F !important;
  font-size: 3.5rem !important;
  border-radius: 50%;
  background: rgba(86, 89, 90, 0.4);
}

.arrowsCarousel button.slick-prev:before{
  font-size: 4rem !important;
}
.slick-next {
  margin-right: 40px;
  right: 0;
}
.slick-prev {
  margin-left: 10px;
  left: 0;
  z-index: 99;
}

@media screen and (max-width: 600px) {
  .slick-next {
    margin-right: 15px;
  }
  button.slick-prev:before, button.slick-next:before {
    font-size: 2rem !important;
  }
  #div-categories .slick-prev,
  #div-categories .slick-next {
    top: 45% !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .slick-next {
    margin-right: 30px;
  }
  button.slick-prev:before, button.slick-next:before {
    font-size: 3rem !important;
  }
  #div-categories .slick-prev,
  #div-categories .slick-next {
    top: 40% !important;
  }
}
</style>
