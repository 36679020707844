<template>
  <div id="download_your_invoices_view">
    <div class="row" v-if="isLoading">
      <div class="col s12 loader-in-div" style="min-height:500px !important">
        <Loader/>
      </div>
    </div>
    <div v-else class="row">
      <div class="note rounded">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form  @submit.prevent="handleSubmit(requestInvoice)" ref="formDownloadInvoices">
            <div class="col s12 left-align">
              <div class="custom-h5 fc-red-petsa">
                Descargue sus facturas
              </div>
              <span v-if="selectedBranch" class="fc-red-petsa">
                <b>Sucursal: </b> {{selectedBranch.name}}
              </span>
            </div>
            <div class="separador">
              <div class="input-field col s12 m12 input-rfc">
                <label class="active" for="rfc-client">RFC</label>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <input id="rfc-client" type="text" class="validate" maxlength="13"
                  v-model="rfc" name="RFC" v-on:keyup="rfcFormat">
                  <span class="helper-text red-text" id="errorRFC">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="input-field col s12 m12">
                <label class="active" for="email-client">Correo electrónico registrado en sucursal</label>
                <ValidationProvider rules="required|email" v-slot="{ errors }">
                  <input id="email-client" type="email" class="validate"
                  v-model="email" name="Correo">
                  <span class="helper-text red-text">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="col s12">
                <div class="tab">
                  <div id="tab-folio" class="tablinks active" v-on:click="openTab('folio')">Por folio</div>
                  <div id="tab-fechas" class="tablinks" v-on:click="openTab('fechas')">Por rango de fechas</div>
                </div>

                <!-- Tab content -->
                <div id="div-folio" class="tabcontent" style="visibility: visible !important; display: block !important;">
                  <div class="input-field">
                    <label for="number-invoice">Folio</label>
                    <input id="number-invoice" type="number" class="validate" required
                      v-model="number" name="Folio">
                    <span class="helper-text red-text" id="errorNumber">
                      &nbsp;
                    </span>
                  </div>
                </div>

                <div id="div-fechas" class="tabcontent">
                  <div class="input-field">
                    <select id="days-select" name="days" v-model="days">
                      <option value="" disabled selected>Selecciona una opción</option>
                      <option value="1">1 día</option>
                      <option value="3">3 días</option>
                      <option value="7">7 días</option>
                      <option value="15">15 días</option>
                      <option value="30">30 días</option>
                      <option value="60">60 días</option>
                      <option value="90">90 días</option>
                    </select>
                    <label>Rango</label>
                  </div>
                  <span class="helper-text red-text" id="errorDays">
                    &nbsp;
                  </span>
                </div>
              </div>

              <div class="col s12" style="text-align: start;">
                <ul>
                  <li class="black-text">
                    He leido el
                    <a class="modal-trigger" href="#modalADP">
                      aviso de privacidad.
                    </a>
                  </li>
                </ul>
                <p>
                  <label>
                    <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                      <input type="checkbox" ref="customer_agreed" v-model="agreed"
                      :checked="agreed" id="customer_agreed" name="De acuerdo" />
                      <span class="black-text">De acuerdo</span>
                      <span class="helper-text red-text">
                        {{ errors[0] }}
                      </span>
                    </ValidationProvider>
                  </label>
                </p>
              </div>

              <div class="input-field col s12 center-align">
                <vue-recaptcha ref="recaptcha" class="g-recaptcha"
                @verify="onVerify" @expired="robot=true"
                sitekey="6LeZNXwiAAAAAGqizf7NyzBDkpb-idF4PYepsIX8">
                </vue-recaptcha>
                <span class="helper-text red-text" id="errorCaptcha">
                  &nbsp;
                </span>
              </div>
            </div>

            <div class="col s12">
              <button class="waves-effect waves-light btn-ch">Enviar</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import M from 'materialize-css'
import api from '@/api';
import apipost from '@/apipost';
import Loader from '../components/Loader/loader.vue'
import {ValidationObserver, ValidationProvider } from 'vee-validate';
import { VueRecaptcha } from 'vue-recaptcha';
import moment from 'moment-timezone';

export default {
  name: 'DownloadInvoices',
  metaInfo: {
    title: 'Facturas',
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'descriptionDownloadInvoices',
        name: 'description',
        content: `Facture fácilmente con Grupo PETSA, descargue sus facturas
        de PETSA y TRAEESA sin complicaciones con nuestra plataforma fácil de usar.`
      },
    ]
  },
  data(){
    return{
      idBranch: this.$route.params.id,
      selectedBranch: null,
      isLoading: false,
      rfc: null,
      email: null,
      number: null,
      days: null,
      isNumber: true,
      robot: true,
      days_range: ['1', '3', '7', '15', '30', '60', '90'],
      agreed: false
    }
  },
  components: {
    Loader,
    ValidationObserver,
    ValidationProvider,
    'vue-recaptcha': VueRecaptcha,
  },
  mounted() {
    this.get_branch();
  },
  updated() {
    var select = document.querySelectorAll('select');
    M.FormSelect.init(select);
  },
  methods:{
    async get_branch () {
      try {
        this.isLoading = true;
        if(this.idBranch == null){
          this.$router.push('/');
        }
        let resp = await api.get('/branches/' + this.idBranch);
        this.selectedBranch = resp.data.data;
        this.isLoading = false;
      }
      catch (error) {
        this.isLoading = false;
        console.log(error);
      }
      await this.$nextTick();
    },
    openTab(opt) {
      var i, tabcontent, tablinks;

      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.visibility = "hidden";
        tabcontent[i].style.display = "none";
      }

      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      let input_number = document.getElementById('number-invoice');
      const select_days = document.getElementById('days-select');
      M.FormSelect.init(select_days);

      if(opt == "folio"){
        input_number.required = true;
        select_days.required = false;
        let event = null;
        select_days.value = "";
        if(typeof(Event) === 'function') {
            event = new Event('change');
        }else{
            event = document.createEvent('Event');
            event.initEvent('change', true, true);
        }
        select_days.dispatchEvent(event);
        this.isNumber = true;
      }else{
        input_number.required = false;
        select_days.required = true;
        input_number.value = "";
        this.isNumber = false;
      }

      document.getElementById('div-' + opt).style.visibility = "visible";
      document.getElementById('div-' + opt).style.display = "block";
      document.getElementById('tab-' + opt).classList.add("active");
    },
    clearForm(){
      this.rfc = null;
      this.email = null;
      this.number = null;
      this.days = null;
      this.robot = false;
      this.isNumber = true;
      this.agreed = false;
    },
    rfcFormat(){
      this.rfc = this.rfc.replace(/[`~!@#$%^*()[\]{}_|+\-=?;:'",.<>\\/ ]/gi, '');
      this.rfc = this.rfc.toUpperCase();
    },
    async requestInvoice(){
      try{
        this.isLoading = true;
        document.getElementById("errorRFC").innerText = '';
        document.getElementById("errorDays").innerText = '';

        let gtag_category = null;
        const re = /^([A-ZÑ&]{3,4}) ?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?([A-Z\d]{2})([A\d])$/;

        if (this.rfc === 'XAXX010101000' || this.rfc == 'XEXX010101000' || !this.rfc.match(re)){
          document.getElementById("errorRFC").innerText = 'El patrón del RFC no es valido';
          this.isLoading = false;
          return false;
        }
        if(this.robot){
          document.getElementById("errorCaptcha").innerText = 'Complete el reCAPTCHA';
          this.isLoading = false;
          return false;
        }
        let request ={};
        if(this.isNumber){
          if(!isNaN(parseInt(this.isNumber))){
            document.getElementById("errorNumber").innerText = 'Ingrese un folio valido';
            this.isLoading = false;
            return false;
          }
          request = {
            customer_rfc: this.rfc,
            customer_email: this.email,
            number: this.number
          }
          gtag_category = 'folio-' + this.number;
        }else{
          if(!this.days_range.includes(this.days)){
            document.getElementById("errorDays").innerText = 'Seleccione un rango valido';
            this.isLoading = false;
            return false;
          }

          let today = new Date();
          let inital_date = new Date();
          let d = parseInt(this.days);
          inital_date.setDate(today.getDate() - d);
          let tz = moment.tz.guess();
          let date_from = moment.tz(this.convertDate(inital_date, true), tz).format();
          let date_to = moment.tz(this.convertDate(today, true), tz).format();

          request = {
            customer_rfc: this.rfc,
            customer_email: this.email,
            date_from: date_from,
            date_to: date_to,
          }
          gtag_category = 'fechas-' + this.convertDate(inital_date, false) + ':'
          + this.convertDate(today, false);
        }

        await apipost.post(`branches/${this.idBranch}/cfdi_mail`, request);

        this.$gtag.event(`descarga-facturas-${this.idBranch}`, {
          'event_category': `descarga-facturas-${this.idBranch}-${this.rfc}-`
            + gtag_category,
          'event_label': `descarga-facturas`,
          'value': 1
        });
        this.isLoading = false;
        this.clearForm();
        M.toast({html: 'Enviamos su(s) factura(s) al correo indicado.',
          classes: 'rounded notification-toast success'});
      }catch (error) {
        this.isLoading = false;
        if (error.response.status == 500) {
          M.toast({html: 'Ocurrio un error en el servidor, intente mas tarde.',
          classes: 'rounded notification-toast warning'});
        } else {
          M.toast({html: 'No se pudo procesar su solicitud.',
          classes: 'rounded notification-toast warning'});
        }
        console.error(error);
        return;
      }
    },
    onVerify(response) {
      document.getElementById("errorCaptcha").innerText = '';
      if (response) this.robot = false;
    },
    convertDate(date, withTime) {
      let yyyy = date.getFullYear().toString();
      let mm = (date.getMonth()+1).toString();
      let dd  = date.getDate().toString();
      let mmChars = mm.split('');
      let ddChars = dd.split('');

      let yyyymmdd = yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' +
      (ddChars[1] ? dd : "0" + ddChars[0]);

      if(withTime){
        let hh = date.getHours().toString();
        let minutes = date.getMinutes().toString();
        let hhChars = hh.split('');
        let minChars = minutes.split('');
        return yyyymmdd + ' ' + (hhChars[1] ? hh : "0" + hhChars[0]) + ':' +
        (minChars[1] ? minutes : "0" + minChars[0]) + ':00';
      }else{
        return yyyymmdd;
      }
    },
  },
  watch: {
    '$route.params.id' () {
      this.idBranch = this.$route.params.id;
      this.get_branch();
    },
  },
}
</script>

<style scoped>

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab .tablinks {
  background: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  width: 50%;
  color: #000;
}

/* Change background color of buttons on hover */
.tab .tablinks:hover {
  background: #ddd;
}

/* Create an active/current tablink class */
.tab .tablinks.active {
  background: #ccc;
}

/* Style the tab content */
.tabcontent {
  visibility: hidden;
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.note {
  position: relative;
  width: 30%;
  padding: 1em 1.5em;
  margin: 2em auto;
  color: #fff;
  background: #E2E4E5;
  overflow: hidden;
}

.note:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 16px 16px 0;
  border-style: solid;
  border-color: #fff #fff #484a4b #363738;
  background: #E2E4E5#56595A;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  /* Firefox 3.0 damage limitation */
  display: block; width: 0;
}

.note.rounded {
  -moz-border-radius: 5px 0 5px 5px;
  border-radius: 5px 0 5px 5px;
}

.note.rounded:before {
  border-width: 8px;
  border-color: #fff #fff transparent transparent;
  -moz-border-radius: 0 0 0 5px;
  border-radius: 0 0 0 5px;
}
.separador{
  border-top: solid 1px #DB272F;
}
.input-rfc{
  margin-top: 2rem;
}

.input-field>label {
  color: #000 !important;
}
.input-field > span > input {
  border-bottom: 1px solid #000 !important;
}
.active-label-date {
    transform: translateY(-14px) scale(0.8) !important;
    transform-origin: 0 0 !important;
}

@media screen and (max-width: 600px) {
  .note {
    width: 90%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .note {
    width: 80%;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1380px) {
  .note {
    width: 60%;
  }
}
</style>