<template>
  <div>
    <VueSlickCarousel v-bind="settings" ref="slickCarousel" id="slickCarousel"
      class="hide-on-med-and-down">
      <div>
        <img class="img-banner" alt="FELEC"
        v-lazy="require('../../assets/banner/felec-w.jpg')">
      </div>
      <div>
        <img class="img-banner" alt="Construwood"
        v-lazy="require('../../assets/banner/construwood-w.jpg')">
      </div>
      <div>
        <img class="img-banner" alt="Follaje"
        v-lazy="require('../../assets/banner/follaje-w.jpg')">
      </div>
    </VueSlickCarousel>

    <VueSlickCarousel v-bind="settings" ref="slickCarousel-movil" id="slickCarousel-movil"
      class="hide-on-large-only">
      <div>
        <img class="img-banner" alt="FELEC"
        v-lazy="require('../../assets/banner/felec-m.jpg')">
      </div>
      <div>
        <img class="img-banner" alt="Construwood"
        v-lazy="require('../../assets/banner/construwood-m.jpg')">
      </div>
      <div>
        <img class="img-banner" alt="Follaje"
        v-lazy="require('../../assets/banner/follaje-m.jpg')">
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Carousel',
  data(){
    return{
      settings:{
        "infinite": true,
        "speed": 1500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "adaptiveHeight": true,
        "autoplay": true,
        "autoplaySpeed": 6500,
        "pauseOnDotsHover": true,
        "pauseOnFocus": true,
        "pauseOnHover": true
      }
    }
  },
  components: { VueSlickCarousel },
  mounted() {
  },
  methods:{
  }
}
</script>

<style scoped>
.slick-slider{
  height: calc(100vw / 3.1 ) !important;
}
.img-banner{
  width: 100% !important;
}
@media screen and (max-width: 992px) {
  .slick-slider{
  height: calc(100vw / 1.8 ) !important;
}
}
</style>
