<template>
  <div id="app">
    <Navbar :number_articles="number_articles" :account="total_account"
      :branches_self_service="branches_self_service" :branches="branches"
      :categories="categories"/>
    <router-view :categories="categories" class="main"></router-view>

    <!-- Modales -->
    <div id="modal1" class="modal">
      <div class="modal-content">
        <div class="row">
          <div class="col s12 left-align">
            <div class="custom-h5 fc-red-petsa">
              Descargue su factura electrónica
            </div>
            <span>Seleccione la <b>sucursal</b> donde realizo su compra</span>
          </div>
          <div class="separador">
            <div class="col s12 m4">
              <img src="./assets/Logos/petsa.png"
                alt="logo-petsa" class="logo-pet"/>
            </div>
            <div class="col s12 m8">
              <b>PETSA</b>
              <div class="collection sucursales-modal collapsible-invoice left-align no-select">
                <a v-for="(b, index) in petsa" :key="index+'petsa'"
                 :href="'/downloadInvoices/' + b.idBranch" class="collection-item">
                 <i class="material-icons left">keyboard_arrow_right</i>
                 {{b.name}}
                </a>
              </div>
            </div>
          </div>
          <div class="separador">
            <div class="col s12 m4">
              <img src="./assets/Logos/petsaexpress.png"
                alt="logo-petsa" class="logo-pet"/>
            </div>
            <div class="col s12 m8">
              <b>PETSA Express</b>
              <div class="collection sucursales-modal collapsible-invoice left-align no-select">
                <a v-for="(b, index) in petsaExpress" :key="index+'petsaExpress'"
                 :href="'/downloadInvoices/' + b.idBranch" class="collection-item">
                 <i class="material-icons left">keyboard_arrow_right</i>
                 {{b.name}}
                </a>
              </div>
            </div>
          </div>
          <div class="separador">
            <div class="col s12 m4">
              <img src="./assets/Logos/traeesa.png"
                alt="logo-petsa" class="logo-pet"/>
            </div>
            <div class="col s12 m8">
              <b>TRAEESA</b>
              <div class="collection sucursales-modal collapsible-invoice left-align no-select">
                <a v-for="(b, index) in traeesa" :key="index+'traeesa'"
                 :href="'/downloadInvoices/' + b.idBranch" class="collection-item">
                 <i class="material-icons left">keyboard_arrow_right</i>
                 {{b.name}}
                </a>
              </div>
            </div>
          </div>
          <div class="separador">
            <div class="col s12 m4">
              <img src="./assets/Logos/traeesaexpress.png"
                alt="logo-petsa" class="logo-pet"/>
            </div>
            <div class="col s12 m8">
              <b>TRAEESA Express</b>
              <div class="collection sucursales-modal collapsible-invoice left-align no-select">
                <a v-for="(b, index) in traeesaExpress" :key="index+'traeesaExpress'"
                 :href="'/downloadInvoices/' + b.idBranch" class="collection-item">
                 <i class="material-icons left">keyboard_arrow_right</i>
                 {{b.name}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modalADP" class="modal">
      <div class="modal-content">
        <div class="row">
          <div class="col s12 left-align">
            <div class="custom-h5 fc-red-petsa">
              Descargue nuestro aviso de privacidad
            </div>
            <span>Seleccione una razón social</span>
          </div>
          <div class="separador">
            <div class="col s12 left-align business-reason">
              <b>Productos Eléctricos de Tijuana SA de CV</b>
            </div>
            <div class="col s12 m4">
              <img src="./assets/Logos/petsa.png"
                alt="logo-petsa" class="logo-pet"/> <br>
              <img src="./assets/Logos/petsaexpress.png"
                alt="logo-petsa" class="logo-pet"/><br>
              <img src="./assets/Logos/PETSAMAYOREO.png"
                alt="logo-petsa" class="logo-pet"/><br>
              <img src="./assets/Logos/petsa-elec-plo.png"
                alt="logo-petsa" class="logo-pet"/>
            </div>
            <div class="col s12 m8 razon-social">
              <a href="https://tinyurl.com/adpPETSA" target="blank">
                <ul class="left-align">
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Tijuana
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Otay
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Ensenada
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Mexicali Lázaro Cárdenas
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Mexicali Gómez Morín
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Mayoreo Rosarito
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Express Tijuana Vía Rápida
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Express Tijuana Cedros
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Express Rosarito
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Express Mexicali
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      PETSA Express Tecate
                  </li>
                </ul>
              </a>
            </div>
          </div>

          <div class="separador">
            <div class="col s12 left-align business-reason">
              <b>Transformadores y Equipos Eléctricos de BCS SA de CV</b>
            </div>
            <div class="col s12 m4">
              <img src="./assets/Logos/traeesa.png"
                alt="logo-petsa" class="logo-pet"/> <br>
              <img src="./assets/Logos/traeesaexpress.png"
                alt="logo-petsa" class="logo-pet"/>
            </div>
            <div class="col s12 m8 razon-social">
              <a href="https://tinyurl.com/adpTRAEESA" target="blank">
                <ul class="left-align">
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      TRAEESA San José del Cabo
                  </li>
                  <li>
                    <i class="tiny material-icons">keyboard_arrow_right</i>
                      TRAEESA Express La Paz
                  </li>
                </ul>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div id="modalContacto" class="modal modal-fixed-footer">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form  @submit.prevent="handleSubmit(requestContact)" ref="formContact">
          <div class="modal-content">
            <div class="row">
              <div class="col s12 left-align">
                <div class="custom-h5 fc-red-petsa">
                  Contáctanos
                </div>
                <span>
                  También puedes comunicarte directamente a alguna de nuestras
                  sucursales, oficinas centrales (664) 687 9000
                  o envíanos un correo electrónico a <b>petsacv@grupopetsa.com</b>
                </span>
              </div>
              <div class="separador">
                <div class="input-field col s12 m6">
                  <label class="active">Dirigido a:</label>
                  <select id="branch_contact" name="Sucursal"
                   v-model="branch_contact">
                    <option value="" disabled selected>Seleccione sucursal</option>
                    <option v-for="b in allBranches" :key="b.id" :value="b.id">
                      <span class="black-text">{{b.name}}</span>
                    </option>
                  </select>
                  <span class="helper-text red-text" id="errorSucursal">
                    &nbsp;
                  </span>
                </div>
                <div class="input-field col s12 m6">
                  <label class="active">Departamento:</label>
                  <select id="departament-contact" name="Departamento"
                    v-model="contact.contact_department">
                    <option value="" disabled selected>
                      Seleccione Departamento
                    </option>
                    <option value="Sales">
                      <span class="black-text">Ventas</span>
                    </option>
                    <option value="CxC">
                      <span class="black-text">Crédito y cobranza</span>
                    </option>
                    <option value="CH">
                      <span class="black-text">Capital Humano</span>
                    </option>
                    <option value="Admon">
                      <span class="black-text">Administración</span>
                    </option>
                    <option value="Marketing">
                      <span class="black-text">Mercadotecnia</span>
                    </option>
                  </select>
                  <span class="helper-text red-text" id="errorDepartamento">
                    &nbsp;
                  </span>
                </div>
                <div class="input-field col s12 m12">
                  <label class="active" for="name-contact">Nombre</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="name-contact" type="text" class="validate"
                    v-model="contact.contact_name" name="Nombre">
                    <span class="helper-text red-text">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="input-field col s12 m6">
                  <label class="active" for="email-contact">Correo electrónico</label>
                  <ValidationProvider rules="required|email" v-slot="{ errors }">
                    <input id="email-contact" type="email" class="validate"
                    v-model="contact.contact_email" name="Correo">
                    <span class="helper-text red-text">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="input-field col s12 m6">
                  <label class="active" for="phone-contact">Número telefónico</label>
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <input id="phone-contact" type="tel" class="validate"
                    v-model="contact.contact_phone">
                    <span class="helper-text red-text">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="input-field col s12 m6">
                  <label class="active" for="company_name-contact">Empresa</label>
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <input id="company_name-contact" type="text" class="validate"
                    v-model="contact.contact_company">
                    <span class="helper-text red-text">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="input-field col s12 m6">
                  <label class="active" for="company_city-contact">Ciudad</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="company_city-contact" type="text" class="validate"
                    v-model="contact.contact_city" name="Ciudad">
                    <span class="helper-text red-text">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="input-field col s12 m12">
                  <label class="active" for="comments-contact">Comentarios</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <textarea id="comments-contact" class="materialize-textarea"
                    v-model="contact.contact_comments" name="Comentarios">
                    </textarea>
                    <span class="helper-text red-text">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="input-field col s12 center-align">
                  <vue-recaptcha ref="recaptcha" class="g-recaptcha"
                  @verify="onVerify" @expired="robot=true"
                  sitekey="6LeZNXwiAAAAAGqizf7NyzBDkpb-idF4PYepsIX8">
                  </vue-recaptcha>
                  <span class="helper-text red-text" id="errorCaptcha">
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a @click="clearContactModal()" class="modal-close waves-effect waves btn-flat">
              Cancelar
            </a>
            <a @click="clearContactModal()" class="waves-effect waves btn-flat">
              Limpiar
            </a>
            <button class="waves-effect waves btn-flat">Enviar</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <SocialChat
      icon
      :attendants="attendants"
      >
      <p style="color: #fff;" slot="header"><b>Haz clic para chatear por WhatsApp.</b></p>
      <template v-slot:button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        >
      </template>
      <small slot="footer">Horario de atención: 8am a 6pm</small>
    </SocialChat>
    <Footer/>
  </div>
</template>

<script>
import Navbar from './components/Navbar/navbar.vue'
import Footer from './components/Footer/footer.vue'
import M from 'materialize-css'
import api from '@/api'
import apipost from '@/apipost'
import _ from 'lodash'
import {ValidationObserver, ValidationProvider } from 'vee-validate'
import { VueRecaptcha } from 'vue-recaptcha'
import { SocialChat } from 'vue-social-chat'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    ValidationObserver,
    ValidationProvider,
    'vue-recaptcha': VueRecaptcha,
    SocialChat,
  },
  metaInfo: {
    titleTemplate: '%s | Grupo PETSA - Productos Eléctricos de Tijuana',
    htmlAttrs: {
      lang: 'es'
    },
    meta: [
      { charset: 'utf-8' },
      {
        name: 'author',
        content: `Productos Eléctricos de Tijuana SA de CV`
      },
      {
        name: 'copyright',
        content: `© Productos Eléctricos de Tijuana SA de CV`
      },
      {
        name: 'robots',
        content: `all`
      },
      {
        name: 'googlebot',
        content: `index follow`
      },
      {
        name: 'publisher',
        content: `https://www.grupopetsa.com/`
      },
    ]
  },
  data () {
    return {
      articles: [],
      number_articles:0,
      total_account:0,
      branches_self_service:[],
      branches:[],
      allBranches:[],
      branch_contact:null,
      contact:{
        contact_department:null,
        contact_name: null,
        contact_email: null,
        contact_phone: null,
        contact_company: null,
        contact_city: null,
        contact_comments: null,
      },
      contact_department:null,
      categories: [],
      subCategories: [],
      distances: [],
      filters: null,
      petsa: [],
      petsaExpress: [],
      traeesa: [],
      traeesaExpress: [],
      robot: true,
      attendants: [
        {
          app: 'whatsapp',
          label: 'Compras y ventas minoristas',
          name: 'Cotizaciones Express',
          number: '526643876763',
          avatar: {
            src: require('./assets/Logos/petsin.png'),
            alt: 'Petsin avatar'
          }
        },
      ],
    }
  },
  created(){
    this.get_branches();
    this.get_categories();
  },
  updated() {
    var select = document.querySelectorAll('select');
    M.FormSelect.init(select);
  },
  mounted(){
    window.scrollTo(0, 0);
    this.getArticles();
    this.globalMethods();
    this.links_to_invoice();
  },
  methods:{
    globalMethods(){
      const thisInstance = this
      this.$root.$on('addArticleEvent', function(newArticle){
        thisInstance.addArticle(newArticle)
      });
      this.$root.$on('deleteArticleEvent', function(article){
        thisInstance.deleteArticle(article)
      });
      this.$root.$on('editArticleEvent', function(art){
        thisInstance.editArticle(art)
      });
      this.$root.$on('editArticleNQEvent', function(id, nq){
        thisInstance.editArticleNewQuantity(id, nq)
      });
      this.$root.$on('undoEmptyCartEvent', function(articles){
        thisInstance.undoEmptyCart(articles)
      });
      this.$root.$on('toB64Event', function(str){
        thisInstance.toB64(str)
      });
      this.$root.$on('fromB64Event', function(str){
        thisInstance.fromB64(str)
      });
    },
    getArticles(){
      if (localStorage.getItem("cartHash")){
        try{
          this.articles = JSON.parse(
            this.fromB64(localStorage.getItem("cartHash"))
          );
          this.number_articles = this.articles.length;
          this.total_account = this.articles.map(a => a.total).
            reduce((prev, next) => prev + next);
        }
        catch(e){
          this.total_account = 0;
          this.number_articles = 0;
        }
      }
    },
    addArticle(newArticle){
      let objIndex = this.articles.findIndex(a => a.id === newArticle.id)

      if(this.articles[objIndex] != null){
        this.articles[objIndex].quantity = this.articles[objIndex].quantity +
          newArticle.quantity;

        this.articles[objIndex].total = this.articles[objIndex].quantity *
          newArticle.price;
      }else{
        newArticle.total = newArticle.price * newArticle.quantity;
        this.articles = [...this.articles, newArticle];
      }
      this.totalCart();
    },
    editArticle(art){
      let objIndex = this.articles.findIndex(a => a.id === art.id)

      if(this.articles[objIndex] != null){
        if(this.articles[objIndex].quantity > 0){
          this.articles[objIndex].quantity = art.quantity;

          this.articles[objIndex].total = this.articles[objIndex].quantity *
            this.articles[objIndex].price;
        }
        else{
          this.deleteArticle(this.articles[objIndex]);
        }
      }

      this.totalCart();
    },
    editArticleNewQuantity(id, nq){
      let objIndex = this.articles.findIndex(a => a.id === id)

      if(this.articles[objIndex] != null){
        if(this.articles[objIndex].quantity > 0 && nq > 0){
          this.articles[objIndex].quantity = nq;

          this.articles[objIndex].total = this.articles[objIndex].price * nq;
        }
        else{
          this.deleteArticle(this.articles[objIndex]);
        }
      }

      this.totalCart();
    },
    undoEmptyCart(articles){
      this.articles = articles;
      this.totalCart();
    },
    deleteArticle(article){
      var exists = this.articles.find(a => a.id === article.id);
      if(exists != null){
        this.articles = this.articles.filter(a => a.id !== article.id);
        this.totalCart();
      }
    },
    totalCart(){
      let totalCart = {};
      if(this.articles.length == 0){
        this.total_account = 0;
        this.number_articles = 0;
        totalCart = {
          total: 0,
          articles: 0,
          items: 0,
        }
      }
      else{
        this.total_account = this.articles.map(a => a.total)
          .reduce((prev, next) => prev + next);
        this.number_articles = this.articles.length;
        let total_items = this.articles.map(a => a.quantity)
          .reduce((prev, next) => prev + next);
        totalCart = {
          total: this.total_account,
          articles: this.number_articles,
          items: total_items,
        }
      }
      localStorage.setItem('totalCartHash',
          this.toB64(JSON.stringify(totalCart)));
    },
    clearContactModal(){
      this.contact={
        contact_department:null,
        contact_name: null,
        contact_email: null,
        contact_phone: null,
        contact_company: null,
        contact_city: null,
        contact_comments: null,
      };
      const selectedBranch = document.querySelector('#branch_contact');
      const selectedDepartament = document.querySelector('#departament-contact');
      M.FormSelect.init(selectedBranch);
      M.FormSelect.init(selectedDepartament);

      let event = null;
      selectedBranch.value = "";
      selectedDepartament.value = "";
      if(typeof(Event) === 'function') {
          event = new Event('change');
      }else{
          event = document.createEvent('Event');
          event.initEvent('change', true, true);
      }
      selectedBranch.dispatchEvent(event);
      selectedDepartament.dispatchEvent(event);

      document.getElementById('name-contact').value = '';
      document.getElementById('email-contact').value = '';
      document.getElementById('phone-contact').value = '';
      document.getElementById('company_name-contact').value = '';
      document.getElementById('company_city-contact').value = '';
      document.getElementById('comments-contact').value = '';
    },
    async get_categories () {
      this.categories = [];
      this.filters = _.assign({},
        { 'parentId': null},
      );

      try {
        let resp = await api.get('categories', {
          params: _.pick(this, 'filters'),
        });
        this.categories = resp.data.data;
        this.categories.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let index = 0;
        for (let category of this.categories) {
          this.filters = _.assign({},
            { 'parentId': category.id, perPage: 'all'},
          );
          let respCat = await api.get('categories', {
            params: _.pick(this, 'filters'),
          });
          let subCategory = respCat.data.data;
          subCategory.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          this.categories[index].children = subCategory;
          index++;
        }
      } catch (error) {
        console.error(error);
      }
      await this.$nextTick();
    },
    async get_branches () {
      localStorage.setItem('defaultBranch', 10000);

      this.branches = [];
      this.branches_self_service = [];
      try {
        let resp = await api.get('branches');

        _(resp).get('data.data').map(i => {
          if(i.name.toLowerCase().includes('express')){
            if(i.name.toLowerCase().includes('petsa')){i.show_offers = true;}
            else if(i.name.toLowerCase().includes('traeesa')){i.show_offers_traeesa = true;}
            else{i.show_offers = false; i.show_offers_traeesa = false;}

            this.branches_self_service.push(i);
          }
          else{
            if(i.name.toLowerCase().includes('traeesa')){i.show_offers_traeesa = true;}
            else{i.show_offers = false; i.show_offers_traeesa = false;}
            this.branches.push(i);
          }
        });

        this.allBranches = resp.data.data;
        this.allBranches.sort((a,b) => (a.name > b.name) ? 1 :
          ((b.name > a.name) ? -1 : 0));
        this.geolocate();
      } catch (error) {
        console.error(error);
      }
      await this.$nextTick();
    },
    get_departament_email(){
      let branchIndex = this.allBranches.find(a => a.id === this.branch_contact);
      let email = 'petsacv@grupopetsa.com';
      this.contact_department = this.contact.contact_department;
      switch(branchIndex.name) {
        case 'PETSA Express Tijuana':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'cristina.gonzalez@grupopetsa.com, gabriela.garcia@grupopetsa.com'; break;
            case 'CxC':      email = 'erika.arriaga@grupopetsa.com'; break;
            case 'CH':       email = 'isaura.padilla@grupopetsa.com'; break;
            case 'Admon':    email = 'ramses.miranda@grupopetsa.com, mayka.hernandez@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
        case 'PETSA Express Rosarito':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'carlos.mancillas@grupopetsa.com, jose.martinez@grupopetsa.com'; break;
            case 'CxC':      email = 'tayde.becerra@grupopetsa.com'; break;
            case 'CH':       email = 'paul.rosales@grupopetsa.com'; break;
            case 'Admon':    email = 'veronica.cazarez@grupopetsa.com, ramses.miranda@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
        case 'PETSA Express Tecate':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'daniel.gutierrez@grupopetsa.com, eduardo.ceniceros@grupopetsa.com'; break;
            case 'CxC':      email = 'cxc.tecate@grupopetsa.com, gustavo.carrillo@grupopetsa.com'; break;
            case 'CH':       email = 'erikseida.soto@grupopetsa.com'; break;
            case 'Admon':    email = 'gerardo.reynoso@grupopetsa.com, ramses.miranda@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
        case 'TRAEESA Express La Paz':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'roberto.garcia@traeesa.com, miguel.cuellar@traeesa.com'; break;
            case 'CxC':      email = 'cxcexpresslapaz@traeesa.com, miguel.cuellar@traeesa.com'; break;
            case 'CH':       email = 'mario.flores@grupopetsa.com, miguel.cuellar@traeesa.com'; break;
            case 'Admon':    email = 'ramses.miranda@grupopetsa.com, miguel.cuellar@traeesa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com, miguel.cuellar@traeesa.com'; break;
          }
          break;
        case 'PETSA Express Mexicali':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'ivan.rangel@grupopetsa.com, angel.flores@grupopetsa.com'; break;
            case 'CxC':      email = 'cxcexpressmex@grupopetsa.com'; break;
            case 'CH':       email = 'petsacv@grupopetsa.com'; break;
            case 'Admon':    email = 'petsacv@grupopetsa.com, ramses.miranda@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
        case 'PETSA Matriz':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'alejandro.valdez@grupopetsa.com, jose.velazco@grupopetsa.com'; break;
            case 'CxC':      email = 'larissa.vargas@grupopetsa.com'; break;
            case 'CH':       email = 'mario.flores@grupopetsa.com'; break;
            case 'Admon':    email = 'alejandro.valdez@grupopetsa.com, yeraldine.martinez@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
        case 'TRAEESA Cabos':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'francisco.legarra@traeesa.com, jaime.leal@traeesa.com'; break;
            case 'CxC':      email = 'karla.azpeitia@traeesa.com'; break;
            case 'CH':       email = 'mario.flores@grupopetsa.com'; break;
            case 'Admon':    email = 'julio.escobedo@grupopetsa.com, yeraldine.martinez@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
        case 'PETSA Mexicali':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'isaac.luna@grupopetsa.com, ventas.mexicali@grupopetsa.com'; break;
            case 'CxC':      email = 'cxc.petsamex@grupopetsa.com'; break;
            case 'CH':       email = 'petsacv@grupopetsa.com'; break;
            case 'Admon':    email = 'isaac.luna@grupopetsa.com, yeraldine.martinez@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
        case 'PETSA Ensenada':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'luis.quintana@grupopetsa.com, dulce.benitez@grupopetsa.com'; break;
            case 'CxC':      email = 'paola.jimenez@grupopetsa.com'; break;
            case 'CH':       email = 'mario.flores@grupopetsa.com'; break;
            case 'Admon':    email = 'luis.quintana@grupopetsa.com, yeraldine.martinez@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
        case 'PETSA Otay':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'jesus.morales@grupopetsa.com, mario.mendoza@grupopetsa.com'; break;
            case 'CxC':      email = 'cxc.otay@grupopetsa.com'; break;
            case 'CH':       email = 'mario.flores@grupopetsa.com'; break;
            case 'Admon':    email = 'adalberto.olmos@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
        case 'PETSA Mexicali GM':
          switch(this.contact.contact_department) {
            case 'Sales':    email = 'cristina.tamayo@grupopetsa.com, americo.varela@grupopetsa.com'; break;
            case 'CxC':      email = 'cxc.mexicaligm@grupopetsa.com'; break;
            case 'CH':       email = 'petsacv@grupopetsa.com'; break;
            case 'Admon':    email = 'cristina.tamayo@grupopetsa.com'; break;
            case 'Marketing':email = 'karen.valencia@grupopetsa.com'; break;
          }
          break;
      }
      this.contact.contact_department = email;
    },
    async requestContact(){
      if(this.branch_contact == null || this.branch_contact == ""){
        document.getElementById("errorSucursal").innerText = 'El campo Sucursal es requerido';
        return;
      }else{
        document.getElementById("errorSucursal").innerText = '';
      }
      if(this.contact.contact_department == null || this.contact.contact_department == ""){
        document.getElementById("errorDepartamento").innerText = 'El campo Sucursal es requerido';
        return
      }else{
        document.getElementById("errorDepartamento").innerText = '';
      }
      if(this.robot){
        document.getElementById("errorCaptcha").innerText = 'Complete el reCAPTCHA';
        return;
      }
      this.get_departament_email();

      try {
        await apipost.post(`branches/${this.branch_contact}/contact`, this.contact);
        this.$gtag.event(`contacto-a-sucursal-${this.branch_contact}`, {
          'event_category': `contacto-a-sucursal-${this.branch_contact}-`
            + this.contact_department + '-' + this.contact.contact_email,
          'event_label': `contacto-a-sucursal`,
          'value': 1
        });
        this.clearContactModal();
        var elem = document.getElementById('modalContacto');
        var instance = M.Modal.getInstance(elem);
        instance.close();
        M.toast({html: 'Recibimos su mensaje, en breve uno de nuestros agentes se comunicará con usted.',
          classes: 'rounded notification-toast success'});

        this.requesting = false;
      }catch (error) {
        this.clearContactModal();

        if (error.response.status == 500) {
          M.toast({html: 'Ocurrio un error en el servidor, intente mas tarde.',
          classes: 'rounded notification-toast warning'});
        } else {
          M.toast({html: 'No se pudo procesar su solicitud.',
          classes: 'rounded notification-toast warning'});
        }
        this.requesting = false;
        console.error(error);
        return;
      }
    },
    onVerify(response) {
      document.getElementById("errorCaptcha").innerText = '';
      if (response) this.robot = false;
    },
    geolocate() {
      if (!localStorage.getItem("selectedBranch")){
        if(navigator.geolocation){
          navigator.geolocation.getCurrentPosition(position => {
            this.getDistances(position, this.branches_self_service, 'Autoservicio');
            if(this.distances.length >= 1){
              this.distances.sort((a,b) => (a.dist > b.dist) ? 1 :
                ((b.dist > a.dist) ? -1 : 0));
              this.$root.$emit('selectBranchEvent', this.distances[0].id,
                this.distances[0].name, this.distances[0].type, true);
            }
          });
        }
      }
    },
    getDistances(position, branches, type){
      let myLat = parseFloat(position.coords.latitude);
      let myLng = parseFloat(position.coords.longitude);
      let myRadLat = Math.PI * myLat/180;
      branches.map(branch => {
        try{
          let branchLat = parseFloat(branch.address.latitude);
          let branchLng = parseFloat(branch.address.longitude);
          let branchRadLat = Math.PI * branchLat/180;
          let theta = myLng-branchLng;
          let radtTheta = Math.PI * theta/180;
          let dist = Math.sin(myRadLat) * Math.sin(branchRadLat) + Math.cos(myRadLat)
            * Math.cos(branchRadLat) * Math.cos(radtTheta);
          if (dist > 1) {
            dist = 1;
          }
          dist = Math.acos(dist);
          dist = dist * 180/Math.PI;
          dist = dist * 60 * 1.1515;//millas
          dist = dist * 1.609344//kilometros
          this.distances.push({
            dist: dist,
            id: branch.id,
            name: branch.name,
            type: type
          });
        }catch{
          console.log('---');
        }
      });
    },
    links_to_invoice () {
      let elemsCollapsible = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elemsCollapsible, {});
      //let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      let shortDate = { year: 'numeric', month: '2-digit', day: '2-digit',};
      this.petsa = [
        {
          name:    'PETSA Tijuana',
          installationDate: new Date(2022, 8, 26).toLocaleDateString("en-US", shortDate),
          idBranch: 40000,
        },
        {
          name:    'PETSA Otay',
          installationDate: new Date(2021, 10, 12).toLocaleDateString("en-US", shortDate),
          idBranch: 120000,
        },
        {
          name:    'PETSA Campos',
          installationDate: new Date(2024, 1, 18).toLocaleDateString("en-US", shortDate),
          idBranch: 220000,
        },
        {
          name:    'PETSA Mexicali Lázaro Cárdenas',
          installationDate: new Date(2022, 2, 25).toLocaleDateString("en-US", shortDate),
          idBranch: 70000,
        },
        {
          name:    'PETSA Mexicali Gómez Morín',
          installationDate: new Date(2022, 1, 18).toLocaleDateString("en-US", shortDate),
          idBranch: 160000,
        },
        {
          name:    'PETSA Ensenada',
          installationDate: new Date(2022, 4, 9).toLocaleDateString("en-US", shortDate),
          idBranch: 80000,
        },
      ];
      this.petsaExpress = [{
          name:    'PETSA Express Tijuana Vía Rápida',
          installationDate: new Date(2022, 9, 10).toLocaleDateString("en-US", shortDate),
          idBranch: 10000,
        },
        {
          name:    'PETSA Express Tijuana Cedros',
          installationDate: new Date(2022, 5, 14).toLocaleDateString("en-US", shortDate),
          idBranch: 170000,
        },
        {
          name:    'PETSA Express Rosarito',
          installationDate: new Date(2022, 8, 12).toLocaleDateString("en-US", shortDate),
          idBranch: 20000,
        },
        {
          name:    'PETSA Express Mexicali',
          installationDate: new Date(2022, 3, 11).toLocaleDateString("en-US", shortDate),
          idBranch: 110000,
        },
        {
          name:    'PETSA Express Tecate',
          installationDate: new Date(2022, 7, 15).toLocaleDateString("en-US", shortDate),
          idBranch: 30000,
        },
        {
          name:    'PETSA Express Ensenada',
          installationDate: new Date(2023, 12, 19).toLocaleDateString("en-US", shortDate),
          idBranch: 210000,
        },
      ];
      this.traeesa = [{
          name:    'TRAEESA San José del Cabo',
          installationDate: new Date(2022, 6, 6).toLocaleDateString("en-US", shortDate),
          idBranch: 60000,
        },
      ];
      this.traeesaExpress = [{
          name:    'TRAEESA Express La Paz',
          installationDate: new Date(2022, 6, 11).toLocaleDateString("en-US", shortDate),
          idBranch: 100000,
        },
      ];
    },
    toB64(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function(match, p1) {
          return String.fromCharCode('0x' + p1);
        }
      ));
    },
    fromB64(str) {
      return decodeURIComponent(Array.prototype.map.call(atob(str),
        function(c) {
          return '%' + c.charCodeAt(0).toString(16);
        }
      ).join(''));
    },
    fillChildren (category) {
      category.children = _.remove(this.subCategories, { parentId: category.id });
      _.each(category.children, this.fillChildren);
    }
  },
  watch: {
    articles: {
      handler() {
        localStorage.setItem('cartHash',
          this.toB64(JSON.stringify(this.articles)));
      },
      deep: true
    }
  },
}
</script>

<style>
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  --vsc-bg-header: #DB272F;
  --vsc-bg-footer:#56595A;
  --vsc-text-color-header: #FFF !important;
  --vsc-text-color-footer: #FFF !important;
  --vsc-bg-button: #25D366;
  --vsc-text-color-button: white;
  --vsc-outline-color: #dcf8c6;
  --vsc-border-color-bottom-header: transparent;
  --vsc-border-color-top-footer: #f3f3f3;
}

@font-face {
  font-family: "Avenir-Bold";
  src: local("Avenir"),
   url(./assets/fonts/Avenir/AvenirNextLTPro-Bold.otf) format("truetype");
}
@font-face {
  font-family: "Avenir-BoldCn";
  src: local("Avenir"),
   url(./assets/fonts/Avenir/AvenirNextLTPro-BoldCn.otf) format("truetype");
}
@font-face {
  font-family: "Avenir-Regular";
  src: local("Avenir"),
   url(./assets/fonts/Avenir/AvenirNextLTPro-Regular.otf) format("truetype");
}
.main{
  flex: 1 1 auto;
}
.contenedor {
  width: 80%;
}
/*Modal de factura*/
.modal {
  width: 60% !important;
}
.logo-pet{
  min-height: 50px;
  height: 50px;
}
.fc-red-petsa{
  color: #DB272F !important;
}
.fc-gray-petsa{
  color: #56595A !important;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Avenir-Bold';
}
p{
  font-family: "Avenir-Regular";
  color: #000;
}
.sucursales-modal > li a,
.razon-social > a ul{
  font-family: "Avenir-Regular";
  text-decoration: none;
  color: #000;
  line-height: 30px;
}
.sucursales-modal li:hover,
.razon-social > a ul:hover {
  background: #E2E4E5;
}

.modal-footer > a{
  font-family: "Avenir-Bold";
}
.separador {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  border-top: solid 1px #DCDAC5;
  min-height: 60px;
}
.business-reason{
  margin: 10px 0 10px 0;
}
.input-field>label {
  color: #56595A !important;
}
.notification-toast{
  border: none !important;
  color: white !important;
  font-weight: 800 !important;
  text-align: center !important;
}
.notification-toast.success{
  background: #DB272F !important;
}
.notification-toast.warning{
  background: #ff9800 !important;
}
/***encabezados custom para mejorar SEO***/
.custom-h1, .custom-h2, .custom-h3, .custom-h4, .custom-h5, .custom-h6 {
  font-weight: 400;
  font-family: 'Avenir-Bold';
}

.custom-h4{
  font-size: 2.28rem;
  line-height: 110%;
  margin: 1.52rem 0 .912rem 0 !important;
}
.custom-h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.custom-h5 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 1.0933333333rem 0 .656rem 0 !important;
}
.custom-h5 {
  display: block;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.custom-h6 {
  font-size: 1.15rem;
  line-height: 110%;
  margin: .7666666667rem 0 .46rem 0 !important;
}
.custom-h6 {
  display: block;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.modal .custom-h1, .modal .custom-h2, .modal .custom-h3, .modal .custom-h4 {
  margin-top: 0 !important;
}
/* label focus color */
input:not([type]):focus:not([readonly])+label,
input[type=text]:not(.browser-default, .search-input, .select_quantity):focus:not([readonly])+label,
input[type=password]:not(.browser-default):focus:not([readonly])+label,
input[type=email]:not(.browser-default):focus:not([readonly])+label,
input[type=url]:not(.browser-default):focus:not([readonly])+label,
input[type=time]:not(.browser-default):focus:not([readonly])+label,
input[type=date]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label,
input[type=tel]:not(.browser-default):focus:not([readonly])+label,
input[type=number]:not(.browser-default):focus:not([readonly])+label,
input[type=search]:not(.browser-default):focus:not([readonly])+label,
textarea.materialize-textarea:focus:not([readonly])+label{
  color: #000 !important;
}
/* label underline focus color */
input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default, .search-input, .select_quantity):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-bottom: 1px solid #56595A !important;
  box-shadow: 0 1px 0 0 #56595A !important;
}
.select-wrapper input.select-dropdown:focus {
  border-bottom: 1px solid #000 !important;
}
/******* paginación **********/
.nav-pag{
  box-shadow: none !important;
  background: none !important;
  height: 30px !important;
  line-height: 30px !important;
  /* noselect */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.list-pag{
  color: #000;
  padding: 0 12px;
  border: none;
}
#div-pagination > nav > ul > li:first-child > a,
#div-pagination > nav > ul > li:last-child > a{
  padding: 0 15px;
}

#div-pagination > nav > ul > li:first-child > a{
  border-radius: 8px 0 0 8px !important;
}
#div-pagination > nav > ul > li:last-child > a{
  border-radius: 0 8px 8px 0 !important
}

#div-pagination > nav > ul > li.active > a{
  background:#DB272F !important;
  color: #fff !important;
  font-weight: 800;

}
#div-pagination > nav > ul > li.VuePagination__pagination-item-prev-chunk,
#div-pagination > nav > ul > li.VuePagination__pagination-item-next-chunk{
  display: none !important;
}

#div-pagination > nav > ul > li.disabled > a,
#div-pagination > nav > ul > li.disabled:hover > a,
#div-pagination > nav > ul > li.disabled:focus {
  color: #999999 !important;
  cursor: not-allowed;
  background: none !important;
}
#div-pagination > nav > ul > li > a:hover,
#div-pagination > nav > ul > li > span:hover{
  background: #EF2D31 !important;
  color: #fff !important;
  font-weight: 800;
}
#div-pagination > nav > ul > li > a:focus,
#div-pagination > nav > ul > li > span:focus {
  background: #AD1F24 !important;
  color: #fff !important;
}
/* Notificacion cambio de sucursal */
#modalCambioSucursal{
  border-radius: 40px !important;
  max-height: 400px !important;
  max-width: 550px !important;
  top: 30% !important;
}
#modalCambioSucursal i{
  font-size: 60px;
}
#modalCambioSucursal .modal-footer{
  text-align: center;
}
#modalCambioSucursal .modal-footer button:not(:first-child) {
  margin-left: 10px;
}

/*titulo*/
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #DB272F;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  font-family: Raleway;
  position: absolute;
  padding: 3px 20px;
  bottom: -0.2em;
  left: 100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
  border-radius: 8px;
}
[data-title] {
  position: relative;
}
/*loader*/
.splash-screen {
  width: 100vw;
  height: 100vh;
  min-height: 400px !important;
  position: fixed;
  z-index: 50;
}
.divloader{
  min-height: 600px;
}
.loader-in-div{
  position: relative !important;
  top: 40% !important;
  left: 50% !important;
  min-height: 300px !important;
  transform: translate(-50%, -10%) !important;
}
.modal-footer > button {
  font-family: "Avenir-Bold";
}
.alert_max{
  color: #ff9800;
  border: 1px solid #ff9800;
  padding: 5px;
  margin: 10px 0 5px 0;
  font-size: 15px !important;
  border-radius: 4px;
}
.inline-icon {
  vertical-align: text-bottom !important;
}
/** Transicion al mostrar/ocultar elementos **/
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(10px);
  opacity: 0;
}
/**Cursor para la revista */
.cursor-left{
  cursor: url('assets/Revista/left-arrow.png'), auto !important;
}
.cursor-right{
  cursor: url('assets/Revista/right-arrow.png'), auto !important;
}
/**Boton card action */
.card .card-action {
  padding: 10px 20px !important;
}
.card .card-action {
  border-top: 0 !important;
}
.card .card-action a,
.btn-add-cart,
.btn-checkout,
.btn-ch {
  font-family: "Avenir-Bold";
  border: none;
  background: #DB272F;
  border-radius: 8px;
  color: #fff !important;
  margin-right: 0 !important;
  text-align: center !important;
  padding: 10px 30px 5px 30px !important;
  cursor: pointer;
}
.card .card-action a:hover,
.btn-add-cart:hover,
.btn-checkout:hover{
  background: #E2E4E5;
  color: #000 !important;
}
/***Alertas */
.v-alert{
  display: flex;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 16px;
  position: relative;
  transition: all 0.35s ease;
  border-radius: 4px;
  color: black!important;
  background: #E2E4E5!important;
  text-align: justify !important;
}

/**Galeria */
.vgs button:focus {
  background: #DB272F !important;
}
/****no select */
.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*****collasible facturas*****/
.collapsible-invoice {
  font-family: 'Avenir-Regular';
  padding: 5px !important;
  font-size: 16px !important;
  font-weight: 600;
}

.collapsible-invoice a.collection-item {
  color: #000 !important;
}

.collapsible-invoice .collection-item {
  padding: 5px 10px !important;
}

.collapsible-invoice a i {
  margin-right: 5px !important;
}

/****SEO*****/
.seo-show{
  display: none !important;
  margin: 0 !important;
  font-size: 0 !important;
}

/********reCAPTCHA**********/
.g-recaptcha > :first-child {
  width: auto !important;
}

@media screen and (max-width: 600px) {
  .contenedor {
    width: 80%;
  }
  .modal {
    width: 90% !important;
  }
  .contenedor h5 {
    font-size: 1rem;
  }
  #modalCambioSucursal{
    max-width: 90% !important;
  }
  .g-recaptcha {
    transform: scale(0.84);
    transform-origin: 0 0;
  }
  .collapsible-invoice-body .collection .collection-item{
    height: 60px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
  .contenedor {
    width: 85%;
  }
  .modal {
    width: 75% !important;
  }
  #modalCambioSucursal{
    max-width: 80% !important;
  }
  .collapsible-invoice-body .collection .collection-item{
    height: 60px !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1280px) {
  .contenedor {
    width: 85% !important;
  }
  .modal {
    width: 70% !important;
  }
  .collapsible-invoice-body .collection .collection-item{
    height: 50px !important;
  }
}
@media only screen and (min-width: 2048px) and (max-width: 2560px) {
  .contenedor {
    width: 70% !important;
  }
  .modal {
    width: 50% !important;
  }
}
@media only screen and (min-width: 2561px) {
  .contenedor {
    width: 60% !important;
  }
  .modal {
    width: 40% !important;
  }
}
</style>
